import { useState, FC, useEffect } from "react";
import {
  TreeSelect,
  TreeSelectChangeEvent,
  TreeSelectSelectionKeysType,
} from "primereact/treeselect";
import { TreeNode } from "primereact/treenode";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
interface TreeSelectInputProps {
  data?: TreeNode[];
  setForm: any;
  form: any;
  placeholder: string;
  formKey: string;
}

const TreeSelectInput: FC<TreeSelectInputProps> = ({
  data,
  setForm,
  form,
  placeholder,
  formKey,
}) => {
  const [selectedNodeKeys, setSelectedNodeKeys] = useState<
    | string
    | TreeSelectSelectionKeysType
    | TreeSelectSelectionKeysType[]
    | null
    | undefined
  >(null);
  const handleChange = (event: TreeSelectChangeEvent) => {
    form[formKey] = event.value;
    setSelectedNodeKeys(event.value);
    setForm(form);
  };
  return (
    <div className="flex justify-content-center">
      <TreeSelect
        value={form[formKey]}
        onChange={handleChange}
        options={data}
        metaKeySelection={false}
        className=" w-full card max-h-[40px]"
        selectionMode="checkbox"
        display="chip"
        placeholder={placeholder}
        filter
        showClear
      ></TreeSelect>
    </div>
  );
};

export default TreeSelectInput;
