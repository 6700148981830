import { IUserState, UserAction } from "../interfaces/Polling";
import { User, Status, Role, Folder } from "../generated/graphql";

export const userInitialState: User = {
  id: "",
  createdAt: "",
  updatedAt: "",
  email: "",
  name: "",
  organization: {
    id: "",
    createdAt: "",
    updatedAt: "",
    organizationName: "",
    organizationEmail: "",
    organizationWebsite: "",
    status: "Review" as Status,
  },
  role: "User" as Role,
  folders: [] as Folder[],
};

export const UserReducer = (
  state: User = userInitialState,
  action: IUserState
) => {
  switch (action.type) {
    case UserAction.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
  }
};
