import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@mui/material";
import { Candidate } from "../generated/graphql";

export default function CandidateCard({ candidate }: { candidate: Candidate }) {
  let color = "bg-primary-main/40";
  if (candidate.state === 1) {
    color = "bg-[#9F6065]/40";
  } else if (candidate.state === 2) {
    color = "bg-[#659F60]/80";
  }
  return (
    <>
      <Paper
        elevation={6}
        className="min-h-[250px] bg-common-white cursor-pointer rounded-sm"
      >
        <Box
          className={`flex min-w-[100%] min-h-[40px] items-center justify-center border-b-black border-b-[1px] border-b-solid ${color}`}
        >
          <h2 className="text-[12px] text-black text-base">
            <b>
              {candidate.title} {candidate.first_name} {candidate.middle_name}{" "}
              {candidate.last_name}
            </b>
          </h2>
        </Box>

        <Grid container className="p-4 min-w-[100%]">
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ overflow: "wrap", color: "#000000", fontSize: "12px" }}
            >
              <b>Fresher: </b> {candidate.fresher}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#000000", fontSize: "12px" }}
            >
              <b>Exp: </b> {candidate.total_work_experience_years}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#000000", fontSize: "12px" }}
            >
              <b>Gender: </b> {candidate.gender}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ overflow: "wrap", color: "#000000", fontSize: "12px" }}
            >
              <b>Age: </b> {candidate.candidate_age}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ overflow: "wrap", color: "#000000", fontSize: "12px" }}
            >
              <b>Lng: </b>{" "}
              {candidate.languages
                ?.split(",")
                .filter(
                  (item: string, index: number) =>
                    candidate.languages?.split(",").indexOf(item) === index
                )
                .join(", ")}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#000000", fontSize: "12px" }}
            >
              <b>Phone: </b> {candidate.mobile_number}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ overflow: "wrap", color: "#000000", fontSize: "12px" }}
            >
              <b>Email: </b> {candidate.primary_email?.toLowerCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              variant="subtitle1"
              sx={{ overflow: "wrap", color: "#000000", fontSize: "12px" }}
              overflow={"hidden"}
            >
              <b>Skills: </b>
              {candidate.skills &&
              candidate.skills
                .split(",")
                .filter(
                  (item: string, index: number) =>
                    candidate.skills &&
                    candidate.skills.split(",").indexOf(item) === index
                )
                .join(", ").length > 250
                ? `${candidate.skills
                    .split(",")
                    .filter(
                      (item: string, index: number) =>
                        candidate.skills &&
                        candidate.skills.split(",").indexOf(item) === index
                    )
                    .join(", ")
                    .substring(0, 250)}...`
                : candidate.skills &&
                  candidate.skills
                    .split(",")
                    .filter(
                      (item: string, index: number) =>
                        candidate.skills &&
                        candidate.skills.split(",").indexOf(item) === index
                    )
                    .join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
