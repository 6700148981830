import { FC, useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import { useApp } from "../states/AppContext";
import { useUpdateOrganizationStatusMutation } from "../generated/graphql";
import { INotification } from "../interfaces/General";
import { Notification } from "../molecules/Notification";

interface OrgListProps {
  orgList: any[];
  setOrgList: React.Dispatch<React.SetStateAction<any[]>>;
}

const OrgList: FC<OrgListProps> = ({ orgList, setOrgList }) => {
  const [state] = useApp();
  const [loading, setLoading] = useState(false);
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [updateOrgStatus] = useUpdateOrganizationStatusMutation();
  const handleSwitchStatus = (id: string, event: any) => {
    setLoading(true);
    updateOrgStatus({
      variables: {
        input: { organizationId: id, status: event.target.value },
      },
    })
      .then(() => {
        const orgListUpdated = orgList.map((org) => {
          if (id === org.id) {
            return { ...org, status: event.target.value };
          } else {
            return org;
          }
        });
        setOrgList(orgListUpdated);
        setNotification({
          message: "Status set",
          open: true,
          type: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        setNotification({
          message: error?.message as string,
          open: true,
          type: "error",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />
      <Paper className="p-5 mt-4">
        {!orgList.length && (
          <Typography variant="h5">There is other organization yet</Typography>
        )}
        <List dense>
          {orgList.map((org) => (
            <ListItem
              key={org.id}
              secondaryAction={
                org.organizationName !==
                  state.organization?.organizationName && (
                  <div className="flex ">
                    <>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {/* <InputLabel sx={{ color: "black" }}>Status</InputLabel> */}
                        <Select
                          sx={{ minWidth: "150px" }}
                          size="small"
                          label="Status"
                          name={org.id}
                          value={org.status}
                          onChange={(event) =>
                            handleSwitchStatus(org.id, event)
                          }
                          fullWidth={true}
                          disabled={loading}
                        >
                          <MenuItem value="Approved">Approved</MenuItem>
                          {org.status === "Review" && (
                            <MenuItem value="Review">Review</MenuItem>
                          )}
                          <MenuItem value="Denied">Denied</MenuItem>
                        </Select>
                      </Stack>
                    </>
                  </div>
                )
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<>{org.organizationName}</>}
                secondary={<>{org.organizationWebsite}</>}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
};

export default OrgList;
