import { Helmet } from "react-helmet-async";
import FeedbackView from "../../organisms/Dashboard/FeedbackView";

const FeedbackPage = () => {
  return (
    <>
      <Helmet>
        <title>Feedback</title>
      </Helmet>
      <FeedbackView />
    </>
  );
};

export default FeedbackPage;
