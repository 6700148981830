import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { Comment } from "../generated/graphql";

interface CommentListProps {
  comments: Comment[];
}

const CommentList: FC<CommentListProps> = ({ comments }) => {
  return (
    <div className="bg-white p-4 m-4 rounded-md">
      {comments.length ? (
        comments.map((comment) => (
          <div
            key={comment.id}
            className="p-2 border-black rounded-md border-1 mt-2"
          >
            <Typography variant="body1">
              <b>{comment.userEmail}</b>
            </Typography>
            <p className="pl-2.5">{comment.comment}</p>
          </div>
        ))
      ) : (
        <>
          <Typography className="flex justify-center">
            <b>No Comments Found</b>
          </Typography>
        </>
      )}
    </div>
  );
};

export default CommentList;
