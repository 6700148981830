import NoPageFoundView from "../../organisms/NoPageFound";

const Page404 = () => {
  return (
    <>
      <title> 404 Page Not Found </title>
      <NoPageFoundView />
    </>
  );
};

export default Page404;
