import { FC, useCallback, useEffect, useState } from "react";
import {
  useGetCandidateResumeFileLazyQuery,
  useGetCandidateResumeLazyQuery,
  useGetDownloadLinkLazyQuery,
} from "../generated/graphql";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
interface DocumentPreviewProps {
  filename?: string | null;
  candidateId?: string | null;
  name?: string | null;
  addToDownload(): any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: "/cmaps",
  standardFontDataUrl: "/standard_fonts",
};

const resizeObserverOptions = {};
const maxWidth = 800;

type PDFFile = string | File | null;

const getMime = (filename?: string | null) => {
  const ext = filename?.split(".").pop();
  if (ext === "doc") {
    return "application/msword";
  } else if (ext === "pdf") {
    return "application/pdf";
  } else if (ext === "docx") {
    return "application/vnd.opencmlformats-officedocument.wordprocessingml.document";
  }
  return "";
};

const DocumentPreview: FC<DocumentPreviewProps> = ({
  filename,
  candidateId,
  name,
  addToDownload,
}) => {
  const [resumeHTML, setResumeHTML] = useState<string>("");
  const [resumeFile, setResumeFile] = useState<File>();
  const [fileLink, setFileLink] = useState<HTMLAnchorElement>();
  const [url, setUrl] = useState<string>("");
  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [getCandidateResumeFile] = useGetCandidateResumeFileLazyQuery({
    onCompleted(data) {
      const base64 = data?.getCandidateDetails?.base64_string as string;
      const mimeType = getMime(filename);
      createFile(base64, mimeType, name, filename);
    },
    onError(error) {
      console.log(error);
    },
  });

  const [getCandidateResumeString] = useGetCandidateResumeLazyQuery({
    onCompleted(data) {
      const string = data?.getCandidateDetails?.raw_string as string;
      getCandidateResumeFile({
        variables: {
          id: candidateId,
        },
      });
      setResumeHTML(string);
    },
    onError(error) {
      console.log(error);
    },
  });
  const createFile = (
    base64_string: string,
    mimeType: string,
    name?: string | null,
    filename?: string | null
  ) => {
    const decodedData = atob(base64_string);
    const arrayBuffer = new ArrayBuffer(decodedData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const fileLink = document.createElement("a");
    fileLink.href = url;
    fileLink.download = name ? name + "." + filename?.split(".").pop() : "";
    const file = new File([blob], filename ? filename : "", { type: mimeType });
    setResumeFile(file);
    setFileLink(fileLink);
    setUrl(url);
  };

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  const handleDownload = () => {
    setLoading(true);
    if (fileLink) {
      addToDownload();
      fileLink.click();
    }
    setLoading(false);
  };

  useResizeObserver(containerRef, resizeObserverOptions, onResize);
  useEffect(() => {
    if (filename && !filename.includes("doc")) {
      getCandidateResumeFile({
        variables: {
          id: candidateId,
        },
      });
    } else {
      getCandidateResumeString({
        variables: {
          id: candidateId,
        },
      });
    }
  }, []);
  return (
    <>
      <Box className="flex justify-between mt-2">
        <Typography variant="h5">Resume</Typography>
        <Button
          onClick={handleDownload}
          sx={{ bgcolor: "#ffffff", marginBottom: "10px" }}
          disabled={loading}
        >
          Download Resume
        </Button>
      </Box>
      {!filename?.includes(".doc") ? (
        <div className="">
          <Document
            file={resumeFile}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={
                  containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                }
              />
            ))}
          </Document>
        </div>
      ) : (
        <>
          <div className="p-3 bg-common-white rounded-md">
            {resumeHTML.split("\n").map((line: string) => {
              return (
                <>
                  <p>{line}</p>
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentPreview;
