import { LineChart } from "@mui/x-charts/LineChart";
import { Metrics } from "../generated/graphql";
import { FC } from "react";
import dayjs from "dayjs";

interface IMetricsChartProps {
  metrics?: Metrics;
}

const MetricsChart: FC<IMetricsChartProps> = ({ metrics }) => {
  return (
    <>
      <div className="p-4">
        {metrics?.viewed?.date && metrics?.viewed?._count && (
          <LineChart
            xAxis={[
              {
                label: "Date",
                data: metrics?.viewed?.date
                  ? metrics?.viewed?.date.map(
                      (data) => new Date(data as string)
                    )
                  : [],
                tickInterval: metrics?.viewed?.date
                  ? metrics?.viewed?.date.map(
                      (data) => new Date(data as string)
                    )
                  : [],
                scaleType: "time",
                valueFormatter: (date) => dayjs(date).format("MMM D"),
              },
            ]}
            series={[
              {
                data: metrics?.viewed?._count,
              },
            ]}
            height={200}
            margin={{ top: 10, bottom: 20 }}
          />
        )}
      </div>
      <div className="p-4">
        {metrics?.downloaded?.date && metrics?.downloaded?._count && (
          <LineChart
            xAxis={[
              {
                label: "Date",
                data: metrics?.downloaded?.date
                  ? metrics?.downloaded?.date.map(
                      (data) => new Date(data as string)
                    )
                  : [],
                tickInterval: metrics?.downloaded?.date
                  ? metrics?.downloaded?.date.map(
                      (data) => new Date(data as string)
                    )
                  : [],
                scaleType: "time",
                valueFormatter: (date) => dayjs(date).format("MMM D"),
              },
            ]}
            series={[
              {
                data: metrics?.downloaded?._count,
              },
            ]}
            height={200}
            margin={{ top: 10, bottom: 20 }}
          />
        )}
      </div>
      <div className="p-4">
        {metrics?.searched?.date && metrics?.searched?._count && (
          <LineChart
            xAxis={[
              {
                label: "Date",
                data: metrics?.searched?.date
                  ? metrics?.searched?.date.map(
                      (data) => new Date(data as string)
                    )
                  : [],
                tickInterval: metrics?.searched?.date
                  ? metrics?.searched?.date.map(
                      (data) => new Date(data as string)
                    )
                  : [],
                scaleType: "time",
                valueFormatter: (date) => dayjs(date).format("MMM D"),
              },
            ]}
            series={[
              {
                data: metrics?.searched?._count,
              },
            ]}
            height={200}
            margin={{ top: 10, bottom: 20 }}
          />
        )}
      </div>
    </>
  );
};

export default MetricsChart;
