import { State, City } from "country-state-city";
import { TreeNode } from "react-dropdown-tree-select";

const states = State.getStatesOfCountry("IN");
const locations: TreeNode[] = [];
states.forEach((state) => {
  const children: TreeNode[] = [];
  City.getCitiesOfState("IN", state.isoCode).forEach((city) => {
    children.push({
      key: `${city.name}`,
      label: city.name,
      value: city.name,
    });
  });
  locations.push({
    key: `${state.name}`,
    label: state.name,
    value: state.name,
    children: children,
  });
});

export default locations;

// import { Country, State, City } from "country-state-city";
// import { TreeNode } from "react-dropdown-tree-select";

// const countries = Country.getAllCountries();
// const locations: TreeNode[] = [];

// countries.forEach((country) => {
//   const countryNode: TreeNode = {
//     key: `${country.isoCode}`,
//     label: country.name,
//     value: country.name,
//     children: [],
//   };

//   const states = State.getStatesOfCountry(country.isoCode);
//   const stateNodes: TreeNode[] = [];

//   states.forEach((state) => {
//     const children: TreeNode[] = [];
//     City.getCitiesOfState(country.isoCode, state.isoCode).forEach((city) => {
//       children.push({
//         key: `${city.name}`,
//         label: city.name,
//         value: city.name,
//       });
//     });
//     stateNodes.push({
//       key: `${state.name}`,
//       label: state.name,
//       value: state.name,
//       children: children,
//     });
//   });

//   countryNode.children = stateNodes;
//   locations.push(countryNode);
// });

// export default locations;
