const dpDegree = [
  "Accounting",
  "Agriculture",
  "Architecture",
  "Aviation",
  "Banking",
  "Chemical",
  "Civil",
  "Computers",
  "Data Science",
  "Education",
  "Electrical",
  "Electronics",
  "Engineering",
  "Export/Import",
  "Fine Arts",
  "Graphic",
  "Hotel Management",
  "Human Resource",
  "Insurance",
  "Journalism",
  "Law",
  "Management",
  "Mechanical",
  "Photography",
  "Pharmacy",
  "Tourism",
  "Visual Arts",
  "Vocational Course",
];

const btechDegree = [
  "Agriculture",
  "Automobile",
  "Aviation",
  "Bio Checmical",
  "Bio Technology",
  "Biomedical",
  "Ceramics",
  "Chemical",
  "Civil",
  "Computers",
  "Electrical",
  "Electronics & Communication",
  "Energy",
  "Environmental",
  "Instrumentation",
  "Infromation Technology",
  "Marine",
  "Mechanical",
  "Metallurgy",
  "Mineral",
  "Mining",
  "Nuclear",
  "Paint Oil",
  "Petroleum",
  "Plastics",
  "Textile",
];

const UgDegree = [
  {
    Degree: "Bachelor of Arts (BA)",
    Majors: [
      "English",
      "History",
      "Political Science",
      "Economics",
      "Sociology",
      "Psychology",
      "Philosophy",
      "Geography",
      "Languages",
    ],
  },
  {
    Degree: "Bachelor of Science (BSc)",
    Majors: [
      "Physics",
      "Chemistry",
      "Biology",
      "Zoology",
      "Botany",
      "Mathematics",
      "Statistics",
      "Computer Science",
      "Biotechnology",
      "Environmental Science",
      "Microbiology",
    ],
  },
  {
    Degree: "Bachelor of Commerce (BCom)",
    Majors: [
      "General Commerce",
      "Accountancy",
      "Finance",
      "Business Management",
      "Taxation",
      "Marketing",
      "E-Commerce",
      "Banking & Insurance",
    ],
  },
  {
    Degree: "Bachelor of Business Administration (BBA)",
    Majors: [
      "Human Resources",
      "Finance",
      "Marketing",
      "International Business",
      "Hospitality",
      "Operations Management",
      "Entrepreneurship",
    ],
  },
  {
    Degree: "Bachelor of Law (LLB)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Education (BEd)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Computer Applications (BCA)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Fine Arts (BFA)",
    Majors: [
      "Painting",
      "Sculpting",
      "Dance",
      "Music",
      "Photography",
      "Applied Arts",
    ],
  },
  {
    Degree: "Bachelor of Architecture (BArch)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Pharmacy (BPharm)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Fashion Technology (BFTech)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Hotel Management (BHM)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Mass Media (BMM)",
    Majors: [],
  },
  {
    Degree: "Bachelor of Physical Education (BPEd)",
    Majors: [],
  },
  {
    Degree: "Hotel Management",
    Majors: [],
  },
  {
    Degree: "Medical",
    Majors: [],
  },
  {
    Degree: "Journalisam",
    Majors: [],
  },
  {
    Degree: "Mass Communication",
    Majors: [],
  },
];

export const underGraduation = [
  {
    key: "Diploma",
    label: "Diploma",
    value: "Diploma",
    children: dpDegree.map((degree) => ({
      key: "Diploma in " + degree,
      label: "Diploma in " + degree,
      value: "Diploma in " + degree,
    })),
  },
  {
    key: "B.Tech/B.E",
    label: "B.Tech/B.E",
    value: "B.Tech/B.E",
    children: btechDegree.map((degree) => ({
      key: "B.Tech/B.E " + degree,
      label: "B.Tech/B.E " + degree,
      value: " Bachelor in Technology/Engineering (B.Tech) (B.E) " + degree,
    })),
  },
  ...UgDegree.map((degree) => ({
    key: degree.Degree,
    label: degree.Degree,
    value: degree.Degree,
    children: degree.Majors.map((majors) => ({
      key: degree.Degree + " in " + majors,
      label: degree.Degree + " in " + majors,
      value: degree.Degree + " in " + majors,
    })),
  })),
];
