import { Parser } from "@json2csv/plainjs";

export const generateCSVFile = (fileData: any[]) => {
    if (fileData.length !== 0) {
      const opts = {};
      const parser = new Parser(opts);
      const csv = parser.parse(fileData);
      return csv;
    }
    return "";
  };