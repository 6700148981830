import React, { FC, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Button, Grid, Stack, alpha, useTheme } from "@mui/material";
import { INotification } from "../interfaces/General";
import { Notification } from "./Notification";
import {
  JobDetails,
  useGetJobDescriptionLazyQuery,
  useGetJobDownloadLinkLazyQuery,
} from "../generated/graphql";

interface JobDetailsCardProps {
  job: JobDetails;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}
const JobDetailsCard: FC<JobDetailsCardProps> = ({ job, setSelected }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [description, setDescription] = useState("");
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [getLink] = useGetJobDownloadLinkLazyQuery({
    onCompleted(data) {
      if (
        data?.getJobDownloadLink &&
        data?.getJobDownloadLink === "No Candidates"
      ) {
        setNotification({
          message: "No Canidates",
          open: true,
          type: "error",
        });
      } else if (data?.getJobDownloadLink && data?.getJobDownloadLink !== "") {
        const fileLink = document.createElement("a");
        fileLink.href = data?.getJobDownloadLink;
        fileLink.download = job.job_code
          ? job.job_code + ".zip"
          : "Applicant.zip";
        fileLink.click();
      }
      setDownload(false);
    },
    onError(error) {
      console.log(error);
      setDownload(false);
    },
  });
  const [getJobDetails] = useGetJobDescriptionLazyQuery({
    onCompleted(data) {
      setDescription(data?.getJobDetails?.job_description as string);
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });

  const handleShow = () => {
    setLoading(true);
    getJobDetails({ variables: { id: job.job_code } });
  };
  const handleDownload = () => {
    if (job.job_code) {
      setDownload(true);
      getLink({
        variables: {
          code: job.job_code,
          job_code: job.job_code,
          global: false,
        },
      });
    }
  };
  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />
      <Card
        elevation={24}
        sx={{
          margin: "auto",
          alignContent: "center",
          minHeight: "80vh",
          padding: "20px",
          bgcolor: alpha(theme.palette.primary.main, 0.2),
        }}
      >
        <Stack direction="row" className="p-4 justify-between">
          <Typography variant="h4" className="px-3">
            {`${job.job_title} - ${job.job_code}`}
          </Typography>
          <Button onClick={() => setSelected(null)}>X</Button>
        </Stack>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={3} md={6} className="p-3">
              <Box className="bg-common-white rounded-md p-3">
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Openings: </b>
                  {job.no_of_openings}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Band: </b>
                  {job.band}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>CTC: </b>
                  {job.ctc_range}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Experience: </b>
                  {job.experience_in_years}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={6} className="p-3">
              <Box className="bg-common-white rounded-md p-3">
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Posting Location: </b>
                  {job.posting_location}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Location: </b>
                  {job.location}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>State: </b>
                  {job.state}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Country: </b>
                  {job.country}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={6} className="p-3">
              <Box className="bg-common-white rounded-md px-3">
                <Typography variant="subtitle1" color="textPrimary">
                  <b>TAT: </b>
                  {job.tat}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Cader Grade: </b>
                  {job.max_grade_cadre}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Function: </b>
                  {job.sub_function}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Requisition Type: </b>
                  {job.requistion_type}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Employment Type: </b>
                  {job.employment_type}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Appointment Type: </b>
                  {job.appointment_type}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Hiring Manager: </b>
                  {job.hiring_manager}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={6} className="p-3">
              <Box className="bg-common-white rounded-md px-3">
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Role: </b>
                  {job.role}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Industry: </b>
                  {job.industryname}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Discipline: </b>
                  {job.discipline}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Min Qualification: </b>
                  {job.minimum_qualification}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Skills: </b>
                  {job.skills}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Languages: </b>
                  {job.working_language}
                </Typography>
                <Typography variant="subtitle1" color="textPrimary">
                  <b>Start Date - Close Date: </b>
                  {job.start_date} {job.closing_date}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box className="px-3">
            <div className="flex justify-between">
              <div>
                {!description && (
                  <Button
                    onClick={() => handleShow()}
                    variant="outlined"
                    sx={{ bgcolor: "#ffffff" }}
                  >
                    Show Description
                  </Button>
                )}
              </div>
              <div>
                <Button
                  onClick={() => handleDownload()}
                  variant="outlined"
                  sx={{ bgcolor: "#ffffff" }}
                  disabled={download}
                >
                  {download
                    ? "Download in Progress"
                    : "Download All Applicants"}
                </Button>
              </div>
            </div>
            {description && (
              <>
                <Typography variant="h4" className="py-3">
                  Description
                </Typography>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={12}
                  className="p-3 bg-common-white rounded-md"
                >
                  {description.split("\n").map((line) => {
                    return (
                      <>
                        <p>{line}</p>
                        <br />
                      </>
                    );
                  })}
                </Grid>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default JobDetailsCard;
