import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Candidate,
  CandidateDetails,
  Folder,
  useDeleteCandidateToFolderMutation,
  useDeleteFolderMutation,
  useGetCandidateDetailsLazyQuery,
  useGetCandidatesbyFolderLazyQuery,
  useGetFolderLinkLazyQuery,
} from "../../generated/graphql";
import { INotification } from "../../interfaces/General";
import { Notification } from "../../molecules/Notification";
import AddFolder from "../../molecules/AddFolder";
import { useApp } from "../../states/AppContext";
import DeleteIcon from "@mui/icons-material/Delete";
import CandidateCard from "../../molecules/CandidateCard";
import CandidateDetailsCard from "../../molecules/CandidateDetailsCard";
import { UserAction } from "../../interfaces/Polling";
import { SubmitButton } from "../../atoms/SubmitButton";

const FoldersView = () => {
  const [state, dispatch] = useApp();
  const [selected, setSelected] = useState<CandidateDetails>();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [download, setdownload] = useState(false);
  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState(1);
  const [candidateList, setCandidateList] = useState<any>([]);
  const [currentFolder, setCurrentFolder] = useState(
    state.folders ? state.folders[0] : null
  );
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [getFolderLink] = useGetFolderLinkLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.getFolderLink && data?.getFolderLink !== "") {
        const fileLink = document.createElement("a");
        fileLink.href = data?.getFolderLink;
        fileLink.download = currentFolder
          ? currentFolder.folderName + ".zip"
          : "";
        fileLink.click();
      }
      setdownload(false);
    },
    onError(error) {
      console.log(error);
      setdownload(false);
    },
  });
  const [getCandidates] = useGetCandidatesbyFolderLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.getCandidatesbyFolder?.count) {
        setCount(data?.getCandidatesbyFolder?.count);
      } else {
        setCount(0);
      }
      const canList = data?.getCandidatesbyFolder?.candidates?.map(
        (candidate) => candidate
      );
      setCandidateList(canList);
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });
  const [deleteCandidate] = useDeleteCandidateToFolderMutation({
    onCompleted(data) {
      setCandidateList([]);
      getCandidates({
        variables: {
          id: currentFolder?.id,
          page: page,
        },
      });
      setNotification({
        message: "Candidate Removed",
        open: true,
        type: "success",
      });
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });
  const [deleteFolder] = useDeleteFolderMutation({
    onCompleted(data) {
      dispatch({
        type: UserAction.SET_USER_DATA,
        payload: {
          ...state,
          folders: state.folders?.filter(
            (obj) => obj?.id !== data.DeleteFolder?.id
          ),
        },
      });
      setCurrentFolder(state.folders ? state.folders[0] : null);
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });
  const [getCanididateDetails, { data: candidateData, error: candidateError }] =
    useGetCandidateDetailsLazyQuery({
      fetchPolicy: "no-cache",
      onCompleted() {
        setSelected(candidateData?.getCandidateDetails as CandidateDetails);
        setLoading(false);
      },
      onError() {
        console.log(candidateError);
        setLoading(false);
      },
    });
  const handleSelect = (event: any) => {
    const name = event.target.value;
    if (state.folders) {
      for (let i = 0; i < state.folders.length; i++) {
        if (state.folders[i]?.folderName === name) {
          setCurrentFolder(state.folders[i] as Folder);
          setPage(1);
          setCandidateList([]);
          if (name !== "Select") {
            getCandidates({
              variables: {
                id: currentFolder?.id,
                page: page,
              },
            });
          }
        }
      }
    }
  };
  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };
  const handleCandidateSelect = (candidate: Candidate) => {
    setLoading(true);
    setSelected({});
    getCanididateDetails({
      variables: {
        id: candidate.candidate_code,
      },
    });
  };
  const handleFolderDelete = () => {
    setLoading(true);
    deleteFolder({
      variables: {
        folderId: currentFolder?.id as string,
      },
    });
  };
  const handleCandidateDelete = (candidateId: string) => {
    setLoading(true);
    deleteCandidate({
      variables: {
        folderId: currentFolder?.id as string,
        candidateId: candidateId,
      },
    });
  };
  const handleDownload = () => {
    if (currentFolder?.id) {
      setdownload(true);
      getFolderLink({
        variables: {
          id: currentFolder?.id,
        },
      });
    }
  };
  useEffect(() => {
    setLoading(true);
    setCandidateList([]);
    getCandidates({
      variables: {
        id: currentFolder?.id,
        page: page,
      },
    });
  }, [page, currentFolder, deleteFolder]);
  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />
      <Container>
        <Paper elevation={3} className="flex flex-col p-5 m-auto gap-3">
          <AddFolder />
          <Stack direction="row">
            <Grid container spacing={2} className="justify-content-between">
              <Grid item xs={10}>
                <FormControl fullWidth size="small">
                  <InputLabel sx={{ color: "black" }}>Folder</InputLabel>
                  <Select
                    name="folder"
                    value={currentFolder?.folderName}
                    label="Folder"
                    onChange={handleSelect}
                    sx={{ bgcolor: "white" }}
                  >
                    <MenuItem key="select" value="Select">
                      Select
                    </MenuItem>
                    {state.folders &&
                      state.folders.map((folder, index) => (
                        <MenuItem key={index} value={folder?.folderName}>
                          {folder?.folderName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  edge="end"
                  title="Delete Folder"
                  aria-label="delete"
                  onClick={handleFolderDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Stack>
        </Paper>
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : currentFolder == null ? (
          <Typography variant="h3" sx={{ textAlign: "center", margin: "auto" }}>
            No Folders Found
          </Typography>
        ) : (
          <>
            <Stack direction="row" className="flex p-2.5 gap-2.5 justify-end">
              <SubmitButton
                onClick={handleDownload}
                className="ml-2"
                disabled={download}
              >
                Download All Profiles
              </SubmitButton>
            </Stack>
            <Stack>
              {!selected && candidateList.length > 0 ? (
                CandidateList(
                  candidateList,
                  handleCandidateSelect,
                  handleCandidateDelete
                )
              ) : (
                <Container sx={{ height: "100vh" }}>
                  <div>
                    {selected && (
                      <Paper>
                        <CandidateDetailsCard
                          candidate={selected}
                          setSelected={setSelected}
                          jobCode={""}
                          global={false}
                        />
                      </Paper>
                    )}
                  </div>
                </Container>
              )}
            </Stack>
          </>
        )}
        {!selected && (
          <Stack direction="row" className="flex p-2.5 gap-2.5 justify-between">
            <Pagination
              count={Math.ceil(count / 8)}
              size="small"
              page={page}
              onChange={handlePageChange}
              className="flex content-center"
            />
            <div>
              <TextField
                size="small"
                name="page"
                label="Page"
                type="number"
                value={pageInput}
                onChange={(event) => {
                  if (
                    !(
                      parseInt(event.target.value) < 1 ||
                      parseInt(event.target.value) > Math.ceil(count / 12)
                    )
                  )
                    setPageInput(parseInt(event.target.value));
                }}
                InputProps={{
                  inputProps: { min: 1, max: Math.ceil(count / 12) },
                }}
                className="max-w-[100px]"
              />
              <SubmitButton
                onClick={() => {
                  setPage(pageInput);
                }}
                className="ml-2"
              >
                Go
              </SubmitButton>
            </div>
          </Stack>
        )}
      </Container>
    </>
  );
};

const CandidateList = (
  candidateList: Candidate[],
  handleCandidateSelect: any,
  handleCandidateDelete: any
) => {
  return (
    <Grid container>
      {candidateList.map((candidate: Candidate) => (
        <Grid
          item
          key={candidate.candidate_code}
          xs={12}
          sm={6}
          md={6}
          sx={{
            padding: "10px",
          }}
        >
          <div onClick={() => handleCandidateSelect(candidate)}>
            <CandidateCard candidate={candidate} />
          </div>
          <div className="flex justify-center mt-2">
            <Button
              fullWidth
              sx={{ background: "#F73D52" }}
              onClick={() =>
                handleCandidateDelete(candidate.candidate_code as string)
              }
            >
              <DeleteIcon />
            </Button>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
export default FoldersView;
