import { Helmet } from "react-helmet-async";
import MetricsView from "../../organisms/Dashboard/MetricsView";

const Metrics = () => {
  return (
    <>
      <Helmet>
        <title>Metrics</title>
      </Helmet>
      <MetricsView />
    </>
  );
};

export default Metrics;
