import ActivateView from "../../organisms/ActivateView";

const ActivatePage = () => {
  return (
    <>
      <title> Activate </title>
      <ActivateView />
    </>
  );
};

export default ActivatePage;
