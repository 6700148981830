import { CandidateDetails } from "../generated/graphql";
import { Parser } from "@json2csv/plainjs";

export const generateCSV = (data: CandidateDetails) => {
    let qual = ""
    let ctc = ""
    let company = ""
    if (data.education)
        for (let i = 0; i < data.education?.length; i++) {
            qual += data.education[i]?.qualification + ', '
        }
    if (data.work_experience)
        for (let i = 0; i < data.work_experience?.length; i++) {
            ctc += data.work_experience[i]?.ctc + ', '
            company += data.work_experience[i]?.company + ', '
        }
    const csvData = [{
        Position: "",
        JobCode: data.job_codes,
        "Candidate Name": `${data.file_name} ${data.last_name}`,
        Gender: data.gender,
        Age: data.candidate_age,
        Qualification: qual,
        Company: company,
        "Exp In Years": data.total_work_experience_years,
        "Current Location": data.current_address,
        CTC: ctc,
        "Notice Period": data.notice_period,
        Skills: data.skills
            ?.split(",")
            .filter(
                (item: any, index: any) =>
                    data.skills?.split(",").indexOf(item) === index
            )
            .join(", "),
        Mobile: data.mobile_number?.toString(),
        Email: data.primary_email,
        "File Name": data.file_name?.includes('.') ? `${data.first_name} ${data.last_name} [${data.total_work_experience_years?.replace(".", "_")}].${data.file_name?.split('.').pop()}` : ""
    }]
    const opts = {};
    const parser = new Parser(opts);
    const csv = parser.parse(csvData);
    const fileName = `${data.first_name} ${data.last_name} [${data.total_work_experience_years?.replace(".", "_")}].csv`;
    const fileType = "text/csv";
    const blob = new Blob([csv], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};