import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  alpha,
  useTheme,
} from "@mui/material";
import {
  Comment,
  CandidateDetails,
  Folder,
  useAddCandidatesToFolderMutation,
  useGetCommentsLazyQuery,
  useAddCandidateToDownloadedMutation,
} from "../generated/graphql";
import { FC, useEffect, useState } from "react";
import DocumentPreview from "../atoms/DocumentPreview";
import { useApp } from "../states/AppContext";
import { INotification } from "../interfaces/General";
import { Notification } from "./Notification";
import CommentInput from "../atoms/CommentInput";
import CommentList from "./CommentList";
import { generateCSV } from "../util/GenerateCSV";
interface CandidateDetailsCardProps {
  candidate: CandidateDetails;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  jobCode: string;
  global: boolean;
}

const CandidateDetailsCard: FC<CandidateDetailsCardProps> = ({
  candidate,
  setSelected,
  jobCode,
  global,
}) => {
  const [state] = useApp();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [addToDownload] = useAddCandidateToDownloadedMutation({
    variables: {
      candidateId: candidate.candidate_code ?? "",
      job_code: jobCode,
      global: global,
    },
  });
  const [currentFolder, setCurrentFolder] = useState<Folder>({
    id: "",
    folderName: "",
  } as Folder);
  const [getComments] = useGetCommentsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.getComments) setComments(data.getComments as Comment[]);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });
  const [addCandidate] = useAddCandidatesToFolderMutation({
    onCompleted(data) {
      setNotification({
        message: "Successfully Added",
        open: true,
        type: "success",
      });
      setAddLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setAddLoading(false);
    },
  });
  const handleShow = () => {
    setLoading(true);
    setShowResume(true);
  };
  const handleAddCandidate = () => {
    setAddLoading(true);
    if (currentFolder != null)
      addCandidate({
        variables: {
          folderId: currentFolder?.id,
          candidateId: [candidate.candidate_code as string],
        },
      });
  };
  const handleSelect = (event: any) => {
    const name = event.target.value;
    if (state.folders) {
      for (let i = 0; i < state.folders.length; i++) {
        if (state.folders[i]?.folderName === name) {
          setCurrentFolder(state.folders[i] as Folder);
        }
      }
    }
  };
  useEffect(() => {
    if (candidate.candidate_code) {
      setLoading(true);
      getComments({
        variables: {
          id: candidate.candidate_code,
        },
      });
    }
  }, []);
  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />

      <Card
        sx={{
          margin: "auto",
          alignContent: "center",
          minHeight: "80vh",
          padding: "20px",
          bgcolor: alpha(theme.palette.primary.main, 0.2),
        }}
      >
        <Stack
          direction="row"
          sx={{
            right: "5%",
            paddingX: "20px",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            {`${candidate.title} ${candidate.first_name} ${candidate.last_name}`}
          </Typography>
          <div className="flex gap-2.5">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Folders</InputLabel>
              <Select
                name="folder"
                value={currentFolder?.folderName}
                label="Folder"
                onChange={handleSelect}
                sx={{ bgcolor: "white" }}
                className="min-w-[150px]"
              >
                {state.folders &&
                  state.folders.map((folder, index) => (
                    <MenuItem key={index} value={folder?.folderName}>
                      {folder?.folderName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              onClick={handleAddCandidate}
              className="p-2"
              variant="outlined"
              sx={{ bgcolor: "#ffffff" }}
              disabled={addLoading}
            >
              <AddIcon />
              Add
            </Button>
            <Button
              variant="outlined"
              onClick={() => setSelected(null)}
              sx={{ bgcolor: "#ffffff" }}
            >
              <CloseIcon />
            </Button>
          </div>
        </Stack>
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              padding: "10px",
            }}
          >
            <Stack
              sx={{ bgcolor: "#ffffff", padding: "10px", borderRadius: "20px" }}
            >
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Email: </b>
                {candidate.primary_email}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Phone: </b>
                {candidate.mobile_number}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Gender: </b>
                {candidate.gender}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Age: </b>
                {candidate.candidate_age}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Lng: </b>
                {candidate.languages
                  ?.split(",")
                  .filter(
                    (item, index) =>
                      candidate.languages?.split(",").indexOf(item) === index
                  )
                  .join(", ")}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Notice Period: </b>
                {candidate.notice_period?.toString()}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Experience: </b>
                {candidate.total_work_experience_years}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Current Address:</b> {candidate.current_address}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Permanent Address:</b> {candidate.permanent_address}
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <b>Source:</b> {candidate.source_type}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              padding: "10px",
            }}
          >
            <Stack
              sx={{ bgcolor: "#ffffff", padding: "10px", borderRadius: "20px" }}
            >
              <Typography variant="h6" gutterBottom>
                Education:
              </Typography>
              <List>
                {candidate.education?.map((edu: any, index: any) => (
                  <Box key={index} className="py-2">
                    <Typography>
                      <b>Qualification:</b> {edu.qualification}
                    </Typography>
                    <Typography>
                      <b>Institute:</b> {edu.institute}
                    </Typography>
                    <Typography>
                      <b>Grade:</b> {edu.grade_percentage}
                    </Typography>
                    <Typography>
                      <b>Start Date:</b>{" "}
                      {edu.start_date !== "0001-01-01" ? edu.start_date : ""}
                    </Typography>
                    <Typography>
                      <b>End Date:</b>{" "}
                      {edu.end_date !== "0001-01-01" ? edu.end_date : ""}
                    </Typography>
                  </Box>
                ))}
              </List>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              padding: "10px",
            }}
          >
            <Stack
              sx={{ bgcolor: "#ffffff", padding: "10px", borderRadius: "20px" }}
            >
              <Typography variant="h6" gutterBottom>
                <b>Skills:</b>
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {candidate.skills
                  ?.split(",")
                  .filter(
                    (item: any, index: any) =>
                      candidate.skills?.split(",").indexOf(item) === index
                  )
                  .join(", ")}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              padding: "10px",
            }}
          >
            <Stack
              sx={{ bgcolor: "#ffffff", padding: "10px", borderRadius: "20px" }}
            >
              <Typography variant="h6" gutterBottom>
                <b>Work Experience:</b>
              </Typography>
              <List>
                {candidate.work_experience?.map((exp: any, index: any) => (
                  <Box key={index} className="py-2">
                    <Typography>
                      <b>Company:</b> {exp.company}
                    </Typography>
                    <Typography>
                      <b>Current Company:</b> {exp.still_working_here}
                    </Typography>
                    <Typography>
                      <b>CTC:</b> {exp.ctc}
                    </Typography>
                    <Typography>
                      <b>Start Date:</b>{" "}
                      {exp.start_date !== "0001-01-01" ? exp.start_date : ""}
                    </Typography>
                    <Typography>
                      <b>End Date:</b>{" "}
                      {exp.end_date !== "0001-01-01" ? exp.end_date : ""}
                    </Typography>
                  </Box>
                ))}
              </List>
            </Stack>
          </Grid>
        </Grid>
        <Box className="px-4">
          <div className="flex justify-between">
            <div>
              {!showResume && candidate.file_name !== "" && (
                <Button
                  variant="outlined"
                  onClick={() => handleShow()}
                  sx={{ bgcolor: "#ffffff" }}
                >
                  Show Resume
                </Button>
              )}
            </div>
            <Button
              variant="outlined"
              sx={{ bgcolor: "#ffffff" }}
              onClick={() => {
                addToDownload();
                generateCSV(candidate);
              }}
            >
              Download Excel
            </Button>
          </div>
          {showResume && (
            <DocumentPreview
              filename={candidate.file_name}
              candidateId={candidate.candidate_code}
              name={`${candidate.first_name} ${
                candidate.last_name
              } [${candidate.total_work_experience_years?.replace(".", "_")}]`}
              addToDownload={addToDownload}
            />
          )}
        </Box>
        <CommentInput
          candidateId={candidate.candidate_code as string}
          setComments={setComments}
          currentComments={comments}
        />
        <CommentList comments={comments} />
      </Card>
    </>
  );
};

export default CandidateDetailsCard;
