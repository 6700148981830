import { useNavigate } from "react-router-dom";
import { Status, User, useGetUserDataLazyQuery } from "../generated/graphql";
import { UserAction } from "../interfaces/Polling";
import { useApp } from "../states/AppContext";

export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const checkToken = () => {
  return !!localStorage.getItem("token");
};

export const useUser = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useApp();
  const setUser = (user: User) => {
    dispatch({
      type: UserAction.SET_USER_DATA,
      payload: {
        id: user.id,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
        name: user.name,
        email: user.email,
        role: user.role,
        folders: user.folders,
        organization: {
          id: user.organization?.id as string,
          createdAt: user.organization?.createdAt,
          updatedAt: user.organization?.createdAt,
          organizationName: user.organization?.organizationName as string,
          organizationWebsite: user.organization?.organizationWebsite,
          organizationEmail: user.organization?.organizationEmail as string,
          status: user.organization?.status as Status,
        },
      },
    });
  };
  const [user, { data, loading, error }] = useGetUserDataLazyQuery({
    onCompleted() {
      setUser(data?.getUserData as User);
    },
    onError(error) {
      console.log(error);
      logout();
      navigate("/user");
    },
  });
  const loginUser = (token?: string) => {
    if (token) {
      setToken(token);
    }
    return user();
  };

  const logout = () => {
    removeToken();
  };

  return {
    user: state,
    isAuthenticated: checkToken(),
    loginUser,
    loading,
    setUser,
    logout,
  };
};
