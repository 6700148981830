import { TreeSelectSelectionKeysType } from "primereact/treeselect";
import SVGColors from "../atoms/SVGColors";

export const HEADER = {
  H_MOBILE: 4,
  H_DESKTOP: 20,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  WIDTH: 280,
};

const icon = (name: string) => (
  <SVGColors
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

export const navConfig = [
  {
    title: "overview",
    path: "overview",
    icon: icon("ic_analytics"),
  },
  {
    title: "search",
    path: "search",
    icon: icon("ic_search"),
  },
  {
    title: "jobs",
    path: "jobs",
    icon: icon("ic_jobs"),
  },
  {
    title: "folders",
    path: "folders",
    icon: icon("ic_folders"),
  },
  {
    title: "feedback",
    path: "feedback",
    icon: icon("ic_feedback"),
  },
];

export const adminConfig = [
  {
    title: "metrics",
    path: "metrics",
    icon: icon("ic_metrics"),
  },
  {
    title: "users",
    path: "users",
    icon: icon("ic_users"),
  },
];

export const maintainerConfig = {
  title: "maintainer",
  path: "maintainer",
  icon: icon("ic_maintainer"),
};

export const settingsConfig = {
  title: "settings",
  path: "settings",
  icon: icon("ic_settings"),
};

export const industries = [
  "Any Industry",
  "Agriculture",
  "Automobiles",
  "Aviation",
  "Banking",
  "Biotechnology",
  "Chemicals",
  "Coal",
  "Construction",
  "Defence",
  "E-commerce",
  "Education and Training",
  "Electronics",
  "Energy",
  "Fast Moving Consumer Goods (FMCG)",
  "Food Processing",
  "Healthcare",
  "Information Technology (IT)",
  "Infrastructure",
  "Insurance",
  "Manufacturing",
  "Media and Entertainment",
  "Mining",
  "Oil and Gas",
  "Pharmaceuticals",
  "Real Estate",
  "Retail",
  "Services",
  "Steel",
  "Telecommunications",
  "Textiles",
  "Tourism and Hospitality",
  "Transportation",
  "Utilities",
];

export const department = [
  "Finance",
  "Human Resources",
  "Information Technology",
  "Marketing",
  "Sales",
  "Research and Development",
  "Production",
  "Purchasing",
  "Legal",
  "Customer Service",
  "Supply Chain",
  "Quality Assurance",
  "Administration",
  "Engineering",
  "Operations",
  "Public Relations",
  "Health and Safety",
  "Education",
  "Training and Development",
  "Corporate Strategy",
  "Business Development",
  "Compliance",
  "Sustainability",
  "Export and Import",
  "Logistics",
  "Procurement",
  "Product Management",
  "Project Management",
  "Risk Management",
  "Data Analysis",
  "Innovation Management",
];

export const searchBody = {
  keywords: [] as string[],
  mustKeyword: false,
  exactKeyword: false,
  mustNotKeyword: false,
  exactNotKeyword: false,
  notKeywords: [] as string[],
  otherLocations: [] as string[],
  gender: "any",
  location: null,
  expMax: "50",
  expMin: "0",
  ctcMax: "100",
  ctcMin: "0",
  eYearMin: (new Date().getFullYear() - 100).toString(),
  eYearMax: new Date().getFullYear().toString(),
  institute: "",
  department: "any",
  industry: ["Any Industry"],
  currentCompany: "",
  currentDesignation: "",
  ugCourse: null,
  pgCourse: null,
  pdCourse: null,
  jobcode: "",
  global: false,
  skills: [] as string[],
  dpCourse: [] as string[],
  fresher: "any",
  ageMin: "18",
  ageMax: "65",
  languages: [] as string[],
  noticePeriod: "99",
  viewed: true,
  downloaded: true,
  sourceTypes: "Any Source",
};

export const sourceTypes = [
  "Any Source",
  "Recruiter",
  "Naukri",
  "Direct",
  "Career Page",
  "Employee Referral",
  "Vendor",
  "IJP",
  "Candidate",
  "Social Media",
  "Newspaper Ad",
  "Monster",
];

export default navConfig;
