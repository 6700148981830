import { FC, useState } from "react";
import { Comment, useAddCommentMutation } from "../generated/graphql";
import { SubmitButton } from "./SubmitButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

interface CommentInputProps {
  candidateId?: string;
  setComments?: any;
  currentComments: Comment[];
}

const CommentInput: FC<CommentInputProps> = ({
  candidateId,
  setComments,
  currentComments,
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [addComment] = useAddCommentMutation({
    onCompleted(data) {
      if (currentComments) setComments([data.AddComment, ...currentComments]);
      else setComments([data.AddComment]);
      setLoading(false);
      setComment("");
    },
    onError(error) {
      setLoading(false);
    },
  });
  const handleSubmit = () => {
    if (comment !== "") {
      if (candidateId) {
        setLoading(true);
        addComment({
          variables: {
            candidateId: candidateId,
            comment: comment,
          },
        });
      }
    }
  };
  return (
    <div className="p-4 m-4 gap-2 content-center bg-white rounded-md">
      <Typography variant="h4"> Comment</Typography>
      <div className="flex gap-2">
        <TextField
          className=""
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button onClick={handleSubmit} disabled={loading} variant="outlined">
          Comment
        </Button>
      </div>
    </div>
  );
};

export default CommentInput;
