import { useEffect, useState } from "react";
import SearchBar from "../../molecules/SearchBar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import JobCard from "../../molecules/JobCard";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import JobDetailsCard from "../../molecules/JobDetailsCard";
import {
  useGetJobsLazyQuery,
  useGetJobLazyQuery,
  useGetJobDetailsLazyQuery,
  Job,
  JobDetails,
} from "../../generated/graphql";
import { CircularProgress, TextField } from "@mui/material";
import { SubmitButton } from "../../atoms/SubmitButton";

const JobsView = () => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<JobDetails>();
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState<Job[]>([]);
  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState(1);
  const [count, setCount] = useState(0);
  const [getJobs, { data: jobsdata, error: jobsError }] = useGetJobsLazyQuery({
    onCompleted() {
      if (jobsdata?.getJobs?.count) {
        setCount(jobsdata?.getJobs?.count);
      } else {
        setCount(0);
      }
      setJobList(jobsdata?.getJobs?.jobs as Job[]);
      setLoading(false);
    },
    onError(jobsError) {
      setLoading(false);
    },
  });

  const [getJob, { data: jobData, error: jobError }] = useGetJobLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted() {
      if (jobData?.getJob?.count) {
        setCount(jobData?.getJob?.count);
      } else {
        setCount(0);
      }
      setJobList(jobData?.getJob?.jobs as Job[]);
      setLoading(false);
    },
    onError(jobError) {
      setLoading(false);
    },
  });
  const [getJobDetails] = useGetJobDetailsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(jobDetails) {
      setSelected(jobDetails.getJobDetails as JobDetails);
      setLoading(false);
    },
    onError(jobDetailsError) {
      setLoading(false);
    },
  });
  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };
  const handleSubmit = () => {
    if (search !== "") {
      setLoading(true);
      getJob({
        variables: {
          query: search,
          page: page - 1,
        },
      });
    }
  };
  const handleSelete = (job: Job) => {
    setLoading(true);
    setSelected({});
    getJobDetails({
      variables: {
        id: job.job_code,
      },
    });
  };
  useEffect(() => {
    if (search === "") {
      setLoading(true);
      getJobs({
        variables: {
          page: page - 1,
        },
      });
    }
  }, [search, page]);
  return (
    <>
      {!selected ? (
        <Container>
          <SearchBar
            field={search}
            setField={setSearch}
            onSubmit={handleSubmit}
            disabled={loading}
          />
          <Stack direction="row" className="flex p-2.5 gap-2.5 justify-between">
            <Pagination
              count={Math.ceil(count / 12)}
              size="small"
              page={page}
              onChange={handlePageChange}
              className="flex content-center"
            />
            <div>
              <TextField
                size="small"
                name="page"
                label="Page"
                type="number"
                value={pageInput}
                onChange={(event) => {
                  if (
                    !(
                      parseInt(event.target.value) < 1 ||
                      parseInt(event.target.value) > Math.ceil(count / 12)
                    )
                  )
                    setPageInput(parseInt(event.target.value));
                }}
                InputProps={{
                  inputProps: { min: 1, max: Math.ceil(count / 12) },
                }}
                className="max-w-[100px]"
              />
              <SubmitButton
                onClick={() => {
                  setPage(pageInput);
                }}
                className="ml-2"
              >
                Go
              </SubmitButton>
            </div>
          </Stack>
          <Stack>
            {loading ? (
              <div className="flex justify-center">
                <CircularProgress size={24} color="inherit" />
              </div>
            ) : jobList.length > 0 ? (
              <Grid container>
                {jobList.map((job: Job) => (
                  <Grid
                    item
                    key={job.job_code}
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                      padding: "10px",
                    }}
                  >
                    <div onClick={() => handleSelete(job)}>
                      <JobCard job={job} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography
                variant="h3"
                sx={{
                  textAlign: "center",
                }}
              >
                No jobs Found
              </Typography>
            )}
          </Stack>
        </Container>
      ) : (
        <Container>
          <JobDetailsCard job={selected} setSelected={setSelected} />
        </Container>
      )}
    </>
  );
};

export default JobsView;
