import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import { Job } from "../generated/graphql";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
interface JobCardProps {
  job: Job;
}

const JobCard: React.FC<JobCardProps> = ({ job }) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={6}
      sx={{
        minHeight: "150px",
        // padding: "20px",
        borderRadius: "15px",
        borderColor: alpha("#000000", 0.5),
        borderWidth: "1px",
        borderStyle: "solid",
        bgcolor: "#ffffff",
      }}
    >
      <Box
        sx={{
          borderTopLeftRadius: "11px",
          borderTopRightRadius: "11px",
          minWidth: "100%",
          bgcolor: alpha(theme.palette.primary.main, 0.4),
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          // direction: "row",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: "#000000",
            margin: "auto",
          }}
        >
          <b>{job.job_title}</b>
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          padding: "10px",
          minWidth: "100%",
        }}
      >
        <Grid item xs={12} sm={3} md={6}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>Code: </b> {job.job_code}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={6}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>Openings: </b> {job.no_of_openings}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} md={6}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>CTC: </b>
            {job.ctc_range}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={6}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>Experience: </b>
            {job.experience_in_years}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={12}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>Location: </b>
            {job.location}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={6}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>Start: </b>
            {job.start_date}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={6}>
          <Typography
            variant="subtitle1"
            sx={{ color: "#000000", fontSize: "12px" }}
          >
            <b>Close: </b>
            {job.closing_date}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobCard;
