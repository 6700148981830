import { FC, useEffect, useState } from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useApp } from "../states/AppContext";
import {
  Exact,
  GetUserByOrganizationQuery,
  Role,
  User,
  useDeleteUserMutation,
  useUpdateUserRoleMutation,
} from "../generated/graphql";
import { INotification } from "../interfaces/General";
import { Notification } from "../molecules/Notification";
import { LazyQueryExecFunction } from "@apollo/client/react/types/types";

interface UserListProps {
  userList: any[];
  setUserList: React.Dispatch<React.SetStateAction<any[]>>;
}

const UserList: FC<UserListProps> = ({ userList, setUserList }) => {
  const [state] = useApp();
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [updateRole] = useUpdateUserRoleMutation({
    onCompleted(data) {
      setNotification({
        message: "Role updated",
        open: false,
        type: "success",
      });
    },
    onError(error) {
      setNotification({
        message: "Unable to update role",
        open: false,
        type: "error",
      });
    },
  });
  const [deleteUser] = useDeleteUserMutation();
  const handleRoleChange = (id: string, role: string) => {
    updateRole({
      variables: {
        input: {
          userId: id,
          role: role as Role,
        },
      },
    });
    const newUserList = [...userList];
    for (let i = 0; i < newUserList.length; i++) {
      if (newUserList[i].id === id) {
        newUserList[i].role = role;
      }
    }
    setUserList(newUserList);
  };
  const handleDelete = (id: string) => {
    deleteUser({
      variables: {
        id: id,
      },
    })
      .then(() => {
        const filterList = userList.filter((user) => user.id !== id);
        setUserList(filterList);
      })
      .catch((error) => {
        setNotification({
          message: error?.message as string,
          open: true,
          type: "error",
        });
      });
  };
  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />

      <Paper className="p-5 mt-4">
        {!userList.length && (
          <Typography variant="h5">There is no users yet</Typography>
        )}
        <List dense>
          {userList
            .sort((a, b) => a.role.localeCompare(b.role))
            .map((user: User) => (
              <div>
                {user.role !== Role.Maintainer && (
                  <ListItem
                    key={user.id}
                    secondaryAction={
                      user.email !== state.email && (
                        <div className="flex ">
                          <>
                            {/* <Stack direction="row" spacing={1} alignItems="center">
                      <Select
                        sx={{ minWidth: "100px" }}
                        size="small"
                        label="Role"
                        value={user.role}
                        onChange={handleSwitchRole}
                      >
                        <MenuItem value="User">User</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                        {state.role === "Maintainer" && (
                          <MenuItem value="Maintainer">Maintainer</MenuItem>
                        )}
                      </Select>
                    </Stack> */}

                            <IconButton
                              edge="end"
                              title="delete user"
                              aria-label="delete"
                              onClick={() => {
                                handleDelete(user.id);
                              }}
                            >
                              <PersonRemoveIcon />
                            </IconButton>
                          </>
                        </div>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PermIdentityIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<>{user.email}</>}
                      secondary={
                        <div>
                          {user.role === Role.Admin ? (
                            <span className="text-red-700">
                              <b>Admin</b>
                            </span>
                          ) : (
                            <span>User</span>
                          )}
                        </div>
                      }
                    />
                    <Select
                      value={user.role}
                      onChange={(e) =>
                        handleRoleChange(user.id, e.target.value)
                      }
                      className="w-[100px]"
                    >
                      <MenuItem value={Role.Admin}>{Role.Admin}</MenuItem>
                      <MenuItem value={Role.User}>{Role.User}</MenuItem>
                    </Select>
                  </ListItem>
                )}
              </div>
            ))}
        </List>
      </Paper>
    </>
  );
};

export default UserList;
