import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Paper from "@mui/material/Paper";
import { Notification } from "../molecules/Notification";
import { INotification } from "../interfaces/General";
import { SubmitButton } from "../atoms/SubmitButton";
import TextField from "@mui/material/TextField";
import { useApp } from "../states/AppContext";
import { useState } from "react";
import { Folder, useCreateFolderMutation } from "../generated/graphql";
import { UserAction } from "../interfaces/Polling";

const AddFolder = () => {
  const [state, dispatch] = useApp();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
  });
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [addFolder] = useCreateFolderMutation({
    onCompleted(data) {
      const folders = state.folders || [];
      dispatch({
        type: UserAction.SET_USER_DATA,
        payload: {
          ...state,
          folders: folders.length
            ? [...folders, data.CreateFolder as Folder]
            : [data.CreateFolder as Folder],
        },
      });
      setNotification({
        message: `Folder Successfully Added`,
        open: true,
        type: "success",
      });
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    setLoading(true);
    addFolder({
      variables: {
        name: formValues.name,
      },
    });
  };
  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TextField
            label="Name"
            value={formValues.name}
            name="name"
            onChange={handleChange}
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <SubmitButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Add"}
          </SubmitButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AddFolder;
