import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const FeedbackView = () => {
  return (
    <Container>
      <Typography variant="h5">Feedback Form</Typography>
      <div className="mt-4">
        <Typography variant="body1">
          Please provide your feedback in the following form{" "}
          <Link
            sx={{ color: "blue" }}
            href="https://forms.microsoft.com/r/mPz3iENAiU"
          >
            here
          </Link>
          .
        </Typography>
      </div>
    </Container>
  );
};

export default FeedbackView;
