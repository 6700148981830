import {
  Container,
  Grid,
  Pagination,
  Stack,
  Box,
  Paper,
  CircularProgress,
  Typography,
  Checkbox,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CandidateDetailsCard from "../../molecules/CandidateDetailsCard";
import CandidateSearch from "../../molecules/CandidateSearch";
import CandidateCard from "../../molecules/CandidateCard";
import { SubmitButton } from "../../atoms/SubmitButton";
import { ISearch } from "../../interfaces/Query";
import { useEffect, useState } from "react";
import {
  Candidate,
  CandidateDetails,
  Folder,
  useAddCandidatesToFolderForQueryMutation,
  useAddCandidatesToFolderMutation,
  useGetCandidateDetailsLazyQuery,
  useGetCandidatesCountLazyQuery,
  useGetCandidatesLazyQuery,
  useGetDownloadLinkLazyQuery,
} from "../../generated/graphql";
import { searchBody } from "../../constants/index";
import { TreeSelectSelectionKeysType } from "primereact/treeselect";
import { INotification } from "../../interfaces/General";
import { Notification } from "../../molecules/Notification";
import { useApp } from "../../states/AppContext";
import AddFolder from "../../molecules/AddFolder";

const nodeToList = (nodes: any) => {
  let data = [];
  for (const x in nodes as TreeSelectSelectionKeysType[]) {
    if (nodes[x].checked) {
      data.push(x);
    }
  }
  return data;
};

const SearchView = () => {
  const [state, dispatch] = useApp();
  const [selected, setSelected] = useState<CandidateDetails>();
  const [message, setMessage] = useState("Search For Candidates");
  const [currentFolder, setCurrentFolder] = useState(
    state.folders ? state.folders[0] : null
  );
  const [check, setCheck] = useState(false);
  const [addBulk, setAddBulk] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [countMessage, setCountMessage] = useState("");
  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState(1);
  const [count, setCount] = useState(0);
  const [formData, setFormData] = useState<ISearch>(searchBody);
  const [candidateList, setCandidateList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [addCandidatesToFolder] = useAddCandidatesToFolderMutation({
    onCompleted(data) {
      setNotification({
        message: "Successfully Added",
        open: true,
        type: "success",
      });
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });
  const [addCandidatesToFolderForQuery] =
    useAddCandidatesToFolderForQueryMutation({
      onCompleted(data) {
        setNotification({
          message: "Successfully Added",
          open: true,
          type: "success",
        });
        setLoading(false);
      },
      onError(error) {
        setNotification({
          message: error?.message as string,
          open: true,
          type: "error",
        });
        setLoading(false);
      },
    });
  const [getDownloadLink] = useGetDownloadLinkLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.getDownloadLink && data?.getDownloadLink !== "") {
        const fileLink = document.createElement("a");
        fileLink.href = data?.getDownloadLink;
        fileLink.download = "candidates.zip";
        fileLink.click();
      }
      setLoading(false);
    },
    onError(error) {
      console.log(error);
      setLoading(false);
    },
  });
  const [getCandidates] = useGetCandidatesLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.getCandidates?.count) {
        setCount(data?.getCandidates?.count);
      } else {
        setCount(0);
        setMessage("No Candidates Found");
      }
      const canList = data?.getCandidates?.candidates?.map(
        (candidate) => candidate
      );
      setCandidateList(canList);
      setLoading(false);
    },
    onError(error) {
      setNotification({
        message: error?.message as string,
        open: true,
        type: "error",
      });
      setLoading(false);
    },
  });
  const [getCanididateDetails] = useGetCandidateDetailsLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted(candidateData) {
      setSelected(candidateData?.getCandidateDetails as CandidateDetails);
      setLoading(false);
    },
    onError(candidateError) {
      console.log(candidateError);
      setLoading(false);
    },
  });
  const [getCount] = useGetCandidatesCountLazyQuery({
    onCompleted(data) {
      const totalCount = data.getCandidatesCount ? data.getCandidatesCount : 0;
      setCountMessage(`Showing results from ${totalCount} Applicants`);
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    setCountMessage("");
    setAddBulk(false);
    setPage(1);
    setCandidateList([]);
    setDownloadList([]);
    const { otherLocations, ...formDataMain } = formData;
    getCandidates({
      variables: {
        query: {
          ...formDataMain,
          location: [...nodeToList(formData.location), ...otherLocations],
          ugCourse: nodeToList(formData.ugCourse),
          pgCourse: nodeToList(formData.pgCourse),
          pdCourse: nodeToList(formData.pdCourse),
        },
        page: page,
      },
    });

    if (formData.jobcode !== "" && formData.global === false) {
      getCount({
        variables: {
          code: formData.jobcode,
        },
      });
      setAddBulk(true);
    }
  };
  const handlePageChange = (event: any, value: any) => {
    setPage(value);
  };
  const handleClear = async () => {
    setFormData({
      ...searchBody,
      ugCourse: null,
      pgCourse: null,
      pdCourse: null,
      location: null,
    });
    setCountMessage("");
    setAddBulk(false);
    setCandidateList([]);
    setDownloadList([]);
    setCount(0);
    setMessage("Search For Candidates");
  };
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: e.target.checked,
    }));
  };
  const handleSelect = (candidate: Candidate) => {
    setLoading(true);
    setSelected({});
    getCanididateDetails({
      variables: {
        id: candidate.candidate_code,
        job_code: formData.jobcode,
        global: formData.global,
      },
    });
  };

  const handleAddQuerySelected = () => {
    if (downloadList.length > 0 && currentFolder) {
      const { otherLocations, ...formDataMain } = formData;
      addCandidatesToFolderForQuery({
        variables: {
          folderId: currentFolder.id,
          query: {
            ...formDataMain,
            location: [...nodeToList(formData.location), ...otherLocations],
            ugCourse: nodeToList(formData.ugCourse),
            pgCourse: nodeToList(formData.pgCourse),
            pdCourse: nodeToList(formData.pdCourse),
          },
        },
      });
    }
  };

  const handleAddSelected = () => {
    if (downloadList.length > 0 && currentFolder) {
      addCandidatesToFolder({
        variables: {
          folderId: currentFolder.id,
          candidateId: downloadList,
        },
      });
    }
  };

  const handleFolderSelect = (event: any) => {
    const name = event.target.value;
    if (state.folders) {
      for (let i = 0; i < state.folders.length; i++) {
        if (state.folders[i]?.folderName === name) {
          setCurrentFolder(state.folders[i] as Folder);
        }
      }
    }
  };

  const handleDownload = () => {
    if (downloadList.length > 0) {
      setLoading(true);
      getDownloadLink({
        variables: {
          ids: downloadList,
          job_code: formData.jobcode,
          global: formData.global,
        },
      });
    }
  };

  const handleDownloadToggle = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    if (e.target.checked) {
      setDownloadList([id, ...downloadList]);
    } else {
      console.log("uncheck");
      const index = downloadList.indexOf(id);
      const temp = downloadList;
      temp.splice(index, 1);
      setDownloadList([...temp]);
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allCandidates = candidateList.map(
      (candidate: Candidate) => candidate.candidate_code
    );
    if (e.target.checked) {
      setDownloadList(allCandidates);
    } else {
      setDownloadList([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    const { otherLocations, ...formDataMain } = formData;
    getCandidates({
      variables: {
        query: {
          ...formDataMain,
          location: [...nodeToList(formData.location), ...otherLocations],
          ugCourse: nodeToList(formData.ugCourse),
          pgCourse: nodeToList(formData.pgCourse),
          pdCourse: [],
        },
        page: page,
      },
    });
  }, [page]);

  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />
      {loading ? (
        <div className="flex justify-center">
          <CircularProgress size={24} color="inherit" />
        </div>
      ) : !selected ? (
        <Container sx={{ height: "100vh" }}>
          <Box sx={{ minHeight: "270px" }}>
            <CandidateSearch formData={formData} setFormData={setFormData} />
          </Box>
          <div className="flex flex-row justify-between items-center bg-common-white my-2 rounded-lg shadow-2xl">
            <div className="flex flex-row p-2.5 gap-2.5">
              <SubmitButton onClick={handleClear}> Clear</SubmitButton>
              <SubmitButton
                onClick={() => {
                  setPage(1);
                  handleSubmit();
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Search"
                )}
              </SubmitButton>
            </div>
            <div className="flex flex-row p-2.5 gap-2.5">
              <div>
                <Checkbox
                  checked={downloadList.length === candidateList.length}
                  onChange={handleSelectAll}
                />
                Select ALL
              </div>
              <SubmitButton onClick={handleDownload} disabled={loading}>
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Download Selected"
                )}
              </SubmitButton>
              <SubmitButton
                onClick={() => {
                  setCheck(true);
                }}
                disabled={loading}
              >
                Add Candidates
              </SubmitButton>
              <div>
                <Dialog
                  disableEscapeKeyDown
                  open={check}
                  onClose={() => {
                    setCheck(false);
                  }}
                >
                  <DialogTitle>Add candidates to folders</DialogTitle>
                  <DialogContent className="flex flex-col min-w-[400px] gap-2.5">
                    <div>
                      <Typography
                        variant="body1"
                        className="flex p-0.5 justify-around"
                      >
                        Create Folder
                      </Typography>
                      <AddFolder />
                    </div>
                    <div>
                      <Typography
                        variant="body1"
                        className="flex p-0.5 justify-around"
                      >
                        Select Folder
                      </Typography>
                      <InputLabel sx={{ color: "black" }}>
                        Select Folder
                      </InputLabel>
                      <Select
                        name="folder"
                        value={currentFolder?.folderName}
                        label="Select Folder"
                        onChange={handleFolderSelect}
                        sx={{ bgcolor: "white" }}
                        fullWidth
                      >
                        <MenuItem key="select" value="Select">
                          Select
                        </MenuItem>
                        {state.folders &&
                          state.folders.map((folder, index) => (
                            <MenuItem key={index} value={folder?.folderName}>
                              {folder?.folderName}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                    <div className="flex flex-col">
                      <SubmitButton
                        onClick={handleAddSelected}
                        disabled={loading}
                      >
                        Add all selected
                      </SubmitButton>
                    </div>
                    {formData.jobcode !== "" && (
                      <div className="flex flex-col">
                        <SubmitButton
                          onClick={handleAddQuerySelected}
                          disabled={loading}
                        >
                          Add all filtered for job code
                        </SubmitButton>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setCheck(false);
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
            <div className="flex flex-row p-2.5 gap-2.5">
              <div className="flex bg-[#9F6065]/30 m-2 rounded-sm">
                <Checkbox
                  size="small"
                  name="viewed"
                  onChange={handleToggle}
                  color="primary"
                  checked={formData.viewed}
                />
                <Typography className="flex content-center m-auto pr-2">
                  Viewed
                </Typography>
              </div>
              <div className="flex bg-[#659F60]/30 m-2 rounded-sm">
                <Checkbox
                  size="small"
                  name="downloaded"
                  onChange={handleToggle}
                  color="primary"
                  checked={formData.downloaded}
                />
                <Typography className="flex content-center m-auto pr-2">
                  Downloaded
                </Typography>
              </div>
            </div>
          </div>
          <Stack>
            {loading ? (
              <></>
            ) : candidateList.length > 0 ? (
              <>
                <Grid container>
                  {candidateList.map((candidate: Candidate) => (
                    <Grid
                      item
                      key={candidate.candidate_code}
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{
                        padding: "10px",
                      }}
                    >
                      <Checkbox
                        className="absolute"
                        sx={{
                          color: grey[800],
                          "&.Mui-checked": {
                            color: grey,
                          },
                        }}
                        checked={downloadList.includes(
                          candidate.candidate_code ?? ""
                        )}
                        onChange={(e) => {
                          handleDownloadToggle(e, candidate.candidate_code);
                        }}
                      />
                      <div onClick={() => handleSelect(candidate)}>
                        <CandidateCard candidate={candidate} />
                      </div>
                    </Grid>
                  ))}
                </Grid>

                <Stack
                  direction="row"
                  className="flex p-2.5 gap-2.5 justify-between"
                >
                  <Pagination
                    count={Math.ceil(count / 8)}
                    size="small"
                    page={page}
                    onChange={handlePageChange}
                    className="content-center"
                  />
                  {countMessage !== "" && (
                    <div className="text-red-600 content-center">
                      {countMessage}
                    </div>
                  )}
                  <div>
                    <TextField
                      size="small"
                      name="page"
                      label="Page"
                      type="number"
                      value={pageInput}
                      onChange={(event) => {
                        if (
                          !(
                            parseInt(event.target.value) < 1 ||
                            parseInt(event.target.value) > Math.ceil(count / 12)
                          )
                        )
                          setPageInput(parseInt(event.target.value));
                      }}
                      InputProps={{
                        inputProps: { min: 1, max: Math.ceil(count / 12) },
                      }}
                      className="max-w-[100px]"
                    />
                    <SubmitButton
                      onClick={() => {
                        setPage(pageInput);
                      }}
                      className="ml-2"
                    >
                      Go
                    </SubmitButton>
                  </div>
                </Stack>
              </>
            ) : (
              <Typography variant="h3" sx={{ textAlign: "center" }}>
                {message}
              </Typography>
            )}
          </Stack>
        </Container>
      ) : (
        <Container sx={{ height: "100vh" }}>
          <div>
            {selected && (
              <Paper>
                <CandidateDetailsCard
                  candidate={selected}
                  setSelected={setSelected}
                  jobCode={formData.jobcode}
                  global={formData.global}
                />
              </Paper>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default SearchView;
