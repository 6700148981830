import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export enum Action {
  Add = 'Add',
  Remove = 'Remove'
}

export type ActivateInput = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Candidate = {
  __typename?: 'Candidate';
  candidate_age?: Maybe<Scalars['Float']['output']>;
  candidate_code?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  fresher?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  mobile_number?: Maybe<Scalars['String']['output']>;
  primary_email?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  total_work_experience_years?: Maybe<Scalars['String']['output']>;
};

export type CandidateDetails = {
  __typename?: 'CandidateDetails';
  base64_string?: Maybe<Scalars['String']['output']>;
  candidate_age?: Maybe<Scalars['Float']['output']>;
  candidate_code?: Maybe<Scalars['String']['output']>;
  current_address?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Array<Maybe<Education>>>;
  file_name?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  fresher?: Maybe<Scalars['String']['output']>;
  functional_area?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  job_codes?: Maybe<Scalars['String']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  languages?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  mobile_number?: Maybe<Scalars['String']['output']>;
  notice_period?: Maybe<Scalars['Float']['output']>;
  permanent_address?: Maybe<Scalars['String']['output']>;
  primary_email?: Maybe<Scalars['String']['output']>;
  raw_string?: Maybe<Scalars['String']['output']>;
  review_body?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Scalars['String']['output']>;
  source_code?: Maybe<Scalars['String']['output']>;
  source_name?: Maybe<Scalars['String']['output']>;
  source_type?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  total_work_experience_years?: Maybe<Scalars['String']['output']>;
  work_experience?: Maybe<Array<Maybe<Work>>>;
};

export type CandidateList = {
  __typename?: 'CandidateList';
  candidates?: Maybe<Array<Maybe<Candidate>>>;
  count: Scalars['Int']['output'];
};

export type CandidateQuery = {
  ageMax?: InputMaybe<Scalars['String']['input']>;
  ageMin?: InputMaybe<Scalars['String']['input']>;
  ctcMax?: InputMaybe<Scalars['String']['input']>;
  ctcMin?: InputMaybe<Scalars['String']['input']>;
  currentCompany?: InputMaybe<Scalars['String']['input']>;
  currentDesignation?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  dpCourse?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eYearMax?: InputMaybe<Scalars['String']['input']>;
  eYearMin?: InputMaybe<Scalars['String']['input']>;
  exactKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  exactNotKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  expMax?: InputMaybe<Scalars['String']['input']>;
  expMin?: InputMaybe<Scalars['String']['input']>;
  fresher?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  institute?: InputMaybe<Scalars['String']['input']>;
  jobcode?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mustKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  mustNotKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  notKeywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noticePeriod?: InputMaybe<Scalars['String']['input']>;
  pdCourse?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pgCourse?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceTypes?: InputMaybe<Scalars['String']['input']>;
  ugCourse?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  candidateId: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
};

export type ConsolidatedReport = {
  __typename?: 'ConsolidatedReport';
  down?: Maybe<Array<Maybe<UsageReport>>>;
  seen?: Maybe<Array<Maybe<UsageReport>>>;
};

export type Count = {
  __typename?: 'Count';
  count?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type Education = {
  __typename?: 'Education';
  end_date?: Maybe<Scalars['String']['output']>;
  grade_percentage?: Maybe<Scalars['String']['output']>;
  institute?: Maybe<Scalars['String']['output']>;
  qualification?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
};

export type Folder = {
  __typename?: 'Folder';
  createdAt: Scalars['DateTime']['output'];
  folderName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  taggedCandidates?: Maybe<Array<Maybe<TaggedCandidate>>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
};

export type InviteOrganizationInput = {
  contactEmail: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  role: Role;
};

export type Job = {
  __typename?: 'Job';
  closing_date?: Maybe<Scalars['String']['output']>;
  ctc_range?: Maybe<Scalars['String']['output']>;
  experience_in_years?: Maybe<Scalars['String']['output']>;
  job_code?: Maybe<Scalars['String']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  no_of_openings?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
};

export type JobDetails = {
  __typename?: 'JobDetails';
  appointment_type?: Maybe<Scalars['String']['output']>;
  band?: Maybe<Scalars['String']['output']>;
  closing_date?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  ctc_range?: Maybe<Scalars['String']['output']>;
  discipline?: Maybe<Scalars['String']['output']>;
  employment_type?: Maybe<Scalars['String']['output']>;
  experience_in_years?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  hiring_manager?: Maybe<Scalars['String']['output']>;
  industryname?: Maybe<Scalars['String']['output']>;
  job_code?: Maybe<Scalars['String']['output']>;
  job_description?: Maybe<Scalars['String']['output']>;
  job_description_unique_words?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  job_title?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  lower_ctc?: Maybe<Scalars['Float']['output']>;
  max_experience?: Maybe<Scalars['Float']['output']>;
  max_grade_cadre?: Maybe<Scalars['String']['output']>;
  min_experience?: Maybe<Scalars['Float']['output']>;
  minimum_qualification?: Maybe<Scalars['String']['output']>;
  no_of_openings?: Maybe<Scalars['Float']['output']>;
  posting_location?: Maybe<Scalars['String']['output']>;
  requistion_type?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  sub_function?: Maybe<Scalars['String']['output']>;
  tat?: Maybe<Scalars['Float']['output']>;
  upper_ctc?: Maybe<Scalars['Float']['output']>;
  working_language?: Maybe<Scalars['String']['output']>;
};

export type JobList = {
  __typename?: 'JobList';
  count: Scalars['Int']['output'];
  jobs?: Maybe<Array<Maybe<Job>>>;
};

export type JwtToken = {
  __typename?: 'JwtToken';
  token: Scalars['String']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Metrics = {
  __typename?: 'Metrics';
  downloaded?: Maybe<Stats>;
  keywords?: Maybe<Array<Maybe<Count>>>;
  searched?: Maybe<Stats>;
  searchedjobCodes?: Maybe<Array<Maybe<Count>>>;
  searchedjobCodesGlobal?: Maybe<Array<Maybe<Count>>>;
  totalDownloaded?: Maybe<Scalars['Int']['output']>;
  totalSearched?: Maybe<Scalars['Int']['output']>;
  totalViewed?: Maybe<Scalars['Int']['output']>;
  viewed?: Maybe<Stats>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ActivateUser?: Maybe<User>;
  AddCandidateToDownloaded?: Maybe<Scalars['Boolean']['output']>;
  AddCandidateToEmailed?: Maybe<Scalars['Boolean']['output']>;
  AddCandidateToSeen?: Maybe<Scalars['Boolean']['output']>;
  AddCandidatesToFolder?: Maybe<Scalars['Boolean']['output']>;
  AddCandidatesToFolderForQuery?: Maybe<Scalars['Boolean']['output']>;
  AddComment?: Maybe<Comment>;
  CreateFolder?: Maybe<Folder>;
  CreateFolderAndAddCandidates?: Maybe<Folder>;
  DeleteCandidateToFolder?: Maybe<TaggedCandidate>;
  DeleteFolder?: Maybe<Folder>;
  DeleteUser?: Maybe<User>;
  ForgotPassword?: Maybe<Scalars['Boolean']['output']>;
  InviteOrganization?: Maybe<Organization>;
  InviteUser?: Maybe<User>;
  LoginUser?: Maybe<UserWithToken>;
  RegisterOrganization?: Maybe<Organization>;
  ResetPasswordUser?: Maybe<User>;
  UpdateOrganizationStatus?: Maybe<Organization>;
  UpdateTransfer?: Maybe<Scalars['Boolean']['output']>;
  UpdateUserRole?: Maybe<User>;
};


export type MutationActivateUserArgs = {
  input: ActivateInput;
};


export type MutationAddCandidateToDownloadedArgs = {
  candidateId: Scalars['String']['input'];
  global?: InputMaybe<Scalars['Boolean']['input']>;
  job_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCandidateToEmailedArgs = {
  candidateId: Scalars['String']['input'];
  global?: InputMaybe<Scalars['Boolean']['input']>;
  job_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCandidateToSeenArgs = {
  candidateId: Scalars['String']['input'];
  global?: InputMaybe<Scalars['Boolean']['input']>;
  job_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCandidatesToFolderArgs = {
  candidateId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  folderId: Scalars['String']['input'];
};


export type MutationAddCandidatesToFolderForQueryArgs = {
  folderId: Scalars['String']['input'];
  query?: InputMaybe<CandidateQuery>;
};


export type MutationAddCommentArgs = {
  candidateId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
};


export type MutationCreateFolderArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateFolderAndAddCandidatesArgs = {
  name: Scalars['String']['input'];
  query?: InputMaybe<CandidateQuery>;
};


export type MutationDeleteCandidateToFolderArgs = {
  candidateId: Scalars['String']['input'];
  folderId: Scalars['String']['input'];
};


export type MutationDeleteFolderArgs = {
  folderId: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationInviteOrganizationArgs = {
  input: InviteOrganizationInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationLoginUserArgs = {
  input: LoginInput;
};


export type MutationRegisterOrganizationArgs = {
  input: RegisterInput;
};


export type MutationResetPasswordUserArgs = {
  input: ResetPasswordInput;
};


export type MutationUpdateOrganizationStatusArgs = {
  input: UpdateStatusInput;
};


export type MutationUpdateTransferArgs = {
  candidates?: InputMaybe<Scalars['Int']['input']>;
  resumes?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateRoleInput;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  organizationEmail: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  organizationWebsite?: Maybe<Scalars['String']['output']>;
  status: Status;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationInput = {
  __typename?: 'OrganizationInput';
  contactEmail: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status?: Maybe<Status>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getCandidateDetails?: Maybe<CandidateDetails>;
  getCandidateExcelData?: Maybe<Scalars['String']['output']>;
  getCandidates?: Maybe<CandidateList>;
  getCandidatesCount?: Maybe<Scalars['Int']['output']>;
  getCandidatesbyFolder?: Maybe<CandidateList>;
  getComments?: Maybe<Array<Maybe<Comment>>>;
  getDownloadLink?: Maybe<Scalars['String']['output']>;
  getFolderExcelData?: Maybe<Scalars['String']['output']>;
  getFolderLink?: Maybe<Scalars['String']['output']>;
  getJob?: Maybe<JobList>;
  getJobDetails?: Maybe<JobDetails>;
  getJobDownloadLink?: Maybe<Scalars['String']['output']>;
  getJobs?: Maybe<JobList>;
  getMetrics?: Maybe<Metrics>;
  getOrganizations?: Maybe<Array<Maybe<Organization>>>;
  getResume?: Maybe<Scalars['String']['output']>;
  getTransfers?: Maybe<Array<Maybe<Transfers>>>;
  getUsageReport?: Maybe<ConsolidatedReport>;
  getUserByOrganization?: Maybe<Array<Maybe<User>>>;
  getUserData?: Maybe<User>;
};


export type QueryGetCandidateDetailsArgs = {
  global?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job_code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCandidateExcelDataArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCandidatesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<CandidateQuery>;
};


export type QueryGetCandidatesCountArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetCandidatesbyFolderArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCommentsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDownloadLinkArgs = {
  global?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  job_code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFolderExcelDataArgs = {
  folder?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetFolderLinkArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetJobArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetJobDetailsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetJobDownloadLinkArgs = {
  code: Scalars['String']['input'];
  global?: InputMaybe<Scalars['Boolean']['input']>;
  job_code?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetJobsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMetricsArgs = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetResumeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTransfersArgs = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterInput = {
  contactEmail: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordInput = {
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum Role {
  Admin = 'Admin',
  Maintainer = 'Maintainer',
  User = 'User'
}

export type Stats = {
  __typename?: 'Stats';
  _count?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  date?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum Status {
  Approved = 'Approved',
  Denied = 'Denied',
  Review = 'Review'
}

export type TaggedCandidate = {
  __typename?: 'TaggedCandidate';
  candidateId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Transfers = {
  __typename?: 'Transfers';
  candidates?: Maybe<Scalars['Int']['output']>;
  resumes?: Maybe<Scalars['Int']['output']>;
};

export type UpdateRoleInput = {
  role: Role;
  userId: Scalars['String']['input'];
};

export type UpdateStatusInput = {
  organizationId: Scalars['String']['input'];
  status: Status;
};

export type UsageReport = {
  __typename?: 'UsageReport';
  candidate_code?: Maybe<Scalars['String']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  ic?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  middle_name?: Maybe<Scalars['String']['output']>;
  mobile_number?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phnumber?: Maybe<Scalars['String']['output']>;
  primary_email?: Maybe<Scalars['String']['output']>;
  psnumber?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  downloaded?: Maybe<Array<Maybe<TaggedCandidate>>>;
  email: Scalars['String']['output'];
  emailed?: Maybe<Array<Maybe<TaggedCandidate>>>;
  folders?: Maybe<Array<Maybe<Folder>>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  password?: Maybe<Scalars['String']['output']>;
  role: Role;
  seen?: Maybe<Array<Maybe<TaggedCandidate>>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserWithToken = {
  __typename?: 'UserWithToken';
  createdAt: Scalars['DateTime']['output'];
  downloaded?: Maybe<Array<Maybe<TaggedCandidate>>>;
  email: Scalars['String']['output'];
  emailed?: Maybe<Array<Maybe<TaggedCandidate>>>;
  folders?: Maybe<Array<Maybe<Folder>>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  password?: Maybe<Scalars['String']['output']>;
  role: Role;
  seen?: Maybe<Array<Maybe<TaggedCandidate>>>;
  updatedAt: Scalars['DateTime']['output'];
  userJwtToken?: Maybe<JwtToken>;
};

export type Work = {
  __typename?: 'Work';
  company?: Maybe<Scalars['String']['output']>;
  ctc?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  still_working_here?: Maybe<Scalars['String']['output']>;
};

export type RegisterOrganizationMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterOrganizationMutation = { __typename?: 'Mutation', RegisterOrganization?: { __typename?: 'Organization', id: string } | null };

export type UpdateOrganizationStatusMutationVariables = Exact<{
  input: UpdateStatusInput;
}>;


export type UpdateOrganizationStatusMutation = { __typename?: 'Mutation', UpdateOrganizationStatus?: { __typename?: 'Organization', id: string } | null };

export type InviteOrganizationMutationVariables = Exact<{
  input: InviteOrganizationInput;
}>;


export type InviteOrganizationMutation = { __typename?: 'Mutation', InviteOrganization?: { __typename?: 'Organization', id: string, organizationName: string, organizationEmail: string, organizationWebsite?: string | null, status: Status } | null };

export type UpdateUserRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', UpdateUserRole?: { __typename?: 'User', id: string } | null };

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', InviteUser?: { __typename?: 'User', id: string, name: string, email: string, password?: string | null, role: Role } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', DeleteUser?: { __typename?: 'User', id: string } | null };

export type ActivateUserMutationVariables = Exact<{
  input: ActivateInput;
}>;


export type ActivateUserMutation = { __typename?: 'Mutation', ActivateUser?: { __typename?: 'User', id: string } | null };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', ForgotPassword?: boolean | null };

export type ResetPasswordUserMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordUserMutation = { __typename?: 'Mutation', ResetPasswordUser?: { __typename?: 'User', id: string } | null };

export type CreateFolderMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateFolderMutation = { __typename?: 'Mutation', CreateFolder?: { __typename?: 'Folder', id: string, folderName: string } | null };

export type DeleteFolderMutationVariables = Exact<{
  folderId: Scalars['String']['input'];
}>;


export type DeleteFolderMutation = { __typename?: 'Mutation', DeleteFolder?: { __typename?: 'Folder', id: string } | null };

export type AddCandidatesToFolderMutationVariables = Exact<{
  folderId: Scalars['String']['input'];
  candidateId?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type AddCandidatesToFolderMutation = { __typename?: 'Mutation', AddCandidatesToFolder?: boolean | null };

export type AddCandidatesToFolderForQueryMutationVariables = Exact<{
  folderId: Scalars['String']['input'];
  query?: InputMaybe<CandidateQuery>;
}>;


export type AddCandidatesToFolderForQueryMutation = { __typename?: 'Mutation', AddCandidatesToFolderForQuery?: boolean | null };

export type DeleteCandidateToFolderMutationVariables = Exact<{
  folderId: Scalars['String']['input'];
  candidateId: Scalars['String']['input'];
}>;


export type DeleteCandidateToFolderMutation = { __typename?: 'Mutation', DeleteCandidateToFolder?: { __typename?: 'TaggedCandidate', id: string } | null };

export type AddCandidateToSeenMutationVariables = Exact<{
  candidateId: Scalars['String']['input'];
}>;


export type AddCandidateToSeenMutation = { __typename?: 'Mutation', AddCandidateToSeen?: boolean | null };

export type AddCandidateToDownloadedMutationVariables = Exact<{
  candidateId: Scalars['String']['input'];
  job_code?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AddCandidateToDownloadedMutation = { __typename?: 'Mutation', AddCandidateToDownloaded?: boolean | null };

export type AddCandidateToEmailedMutationVariables = Exact<{
  candidateId: Scalars['String']['input'];
}>;


export type AddCandidateToEmailedMutation = { __typename?: 'Mutation', AddCandidateToEmailed?: boolean | null };

export type LoginUserMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', LoginUser?: { __typename?: 'UserWithToken', userJwtToken?: { __typename?: 'JwtToken', token: string } | null } | null };

export type AddCommentMutationVariables = Exact<{
  candidateId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
}>;


export type AddCommentMutation = { __typename?: 'Mutation', AddComment?: { __typename?: 'Comment', id: string, comment: string, userEmail: string } | null };

export type GetUserDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDataQuery = { __typename?: 'Query', getUserData?: { __typename?: 'User', id: string, email: string, name: string, role: Role, organization?: { __typename?: 'Organization', id: string, organizationName: string, organizationEmail: string, organizationWebsite?: string | null, status: Status } | null, folders?: Array<{ __typename?: 'Folder', id: string, folderName: string } | null> | null } | null };

export type GetJobQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetJobQuery = { __typename?: 'Query', getJob?: { __typename?: 'JobList', count: number, jobs?: Array<{ __typename?: 'Job', job_code?: string | null, job_title?: string | null, location?: string | null, ctc_range?: string | null, experience_in_years?: string | null, no_of_openings?: string | null, closing_date?: string | null, start_date?: string | null } | null> | null } | null };

export type GetJobsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetJobsQuery = { __typename?: 'Query', getJobs?: { __typename?: 'JobList', count: number, jobs?: Array<{ __typename?: 'Job', job_code?: string | null, job_title?: string | null, location?: string | null, ctc_range?: string | null, experience_in_years?: string | null, no_of_openings?: string | null, closing_date?: string | null, start_date?: string | null } | null> | null } | null };

export type GetJobDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetJobDetailsQuery = { __typename?: 'Query', getJobDetails?: { __typename?: 'JobDetails', appointment_type?: string | null, band?: string | null, closing_date?: string | null, country?: string | null, ctc_range?: string | null, discipline?: string | null, employment_type?: string | null, experience_in_years?: string | null, function?: string | null, hiring_manager?: string | null, industryname?: string | null, job_code?: string | null, job_title?: string | null, location?: string | null, max_grade_cadre?: string | null, minimum_qualification?: string | null, no_of_openings?: number | null, posting_location?: string | null, requistion_type?: string | null, role?: string | null, skills?: string | null, start_date?: string | null, state?: string | null, sub_function?: string | null, tat?: number | null, working_language?: string | null } | null };

export type GetJobDescriptionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetJobDescriptionQuery = { __typename?: 'Query', getJobDetails?: { __typename?: 'JobDetails', job_description?: string | null } | null };

export type GetCandidatesQueryVariables = Exact<{
  query?: InputMaybe<CandidateQuery>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCandidatesQuery = { __typename?: 'Query', getCandidates?: { __typename?: 'CandidateList', count: number, candidates?: Array<{ __typename?: 'Candidate', candidate_code?: string | null, title?: string | null, first_name?: string | null, middle_name?: string | null, last_name?: string | null, fresher?: string | null, gender?: string | null, languages?: string | null, mobile_number?: string | null, primary_email?: string | null, skills?: string | null, total_work_experience_years?: string | null, candidate_age?: number | null, state?: number | null } | null> | null } | null };

export type GetCandidateDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  job_code?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetCandidateDetailsQuery = { __typename?: 'Query', getCandidateDetails?: { __typename?: 'CandidateDetails', candidate_code?: string | null, title?: string | null, first_name?: string | null, middle_name?: string | null, last_name?: string | null, fresher?: string | null, gender?: string | null, languages?: string | null, mobile_number?: string | null, notice_period?: number | null, primary_email?: string | null, skills?: string | null, source_code?: string | null, source_type?: string | null, source_name?: string | null, total_work_experience_years?: string | null, candidate_age?: number | null, functional_area?: string | null, permanent_address?: string | null, current_address?: string | null, job_codes?: string | null, job_title?: string | null, file_name?: string | null, education?: Array<{ __typename?: 'Education', qualification?: string | null, institute?: string | null, start_date?: string | null, end_date?: string | null, grade_percentage?: string | null } | null> | null, work_experience?: Array<{ __typename?: 'Work', company?: string | null, still_working_here?: string | null, ctc?: string | null, start_date?: string | null, end_date?: string | null } | null> | null } | null };

export type GetCandidateResumeQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCandidateResumeQuery = { __typename?: 'Query', getCandidateDetails?: { __typename?: 'CandidateDetails', raw_string?: string | null } | null };

export type GetCandidateResumeFileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCandidateResumeFileQuery = { __typename?: 'Query', getCandidateDetails?: { __typename?: 'CandidateDetails', base64_string?: string | null } | null };

export type GetCandidatesbyFolderQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCandidatesbyFolderQuery = { __typename?: 'Query', getCandidatesbyFolder?: { __typename?: 'CandidateList', count: number, candidates?: Array<{ __typename?: 'Candidate', candidate_code?: string | null, title?: string | null, first_name?: string | null, middle_name?: string | null, last_name?: string | null, fresher?: string | null, gender?: string | null, languages?: string | null, mobile_number?: string | null, primary_email?: string | null, skills?: string | null, total_work_experience_years?: string | null, candidate_age?: number | null } | null> | null } | null };

export type GetResumeQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetResumeQuery = { __typename?: 'Query', getResume?: string | null };

export type GetFolderExcelDataQueryVariables = Exact<{
  folder?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetFolderExcelDataQuery = { __typename?: 'Query', getFolderExcelData?: string | null };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', getOrganizations?: Array<{ __typename?: 'Organization', id: string, organizationName: string, organizationEmail: string, organizationWebsite?: string | null, status: Status } | null> | null };

export type GetUserByOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserByOrganizationQuery = { __typename?: 'Query', getUserByOrganization?: Array<{ __typename?: 'User', id: string, name: string, email: string, role: Role } | null> | null };

export type GetDownloadLinkQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  job_code?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetDownloadLinkQuery = { __typename?: 'Query', getDownloadLink?: string | null };

export type GetFolderLinkQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetFolderLinkQuery = { __typename?: 'Query', getFolderLink?: string | null };

export type GetCommentsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetCommentsQuery = { __typename?: 'Query', getComments?: Array<{ __typename?: 'Comment', id: string, comment: string, userEmail: string } | null> | null };

export type GetCandidatesCountQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type GetCandidatesCountQuery = { __typename?: 'Query', getCandidatesCount?: number | null };

export type GetMetricsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
}>;


export type GetMetricsQuery = { __typename?: 'Query', getMetrics?: { __typename?: 'Metrics', totalDownloaded?: number | null, totalSearched?: number | null, totalViewed?: number | null, viewed?: { __typename?: 'Stats', _count?: Array<number | null> | null, date?: Array<string | null> | null } | null, downloaded?: { __typename?: 'Stats', _count?: Array<number | null> | null, date?: Array<string | null> | null } | null, searched?: { __typename?: 'Stats', _count?: Array<number | null> | null, date?: Array<string | null> | null } | null, keywords?: Array<{ __typename?: 'Count', count?: number | null, key?: string | null } | null> | null, searchedjobCodesGlobal?: Array<{ __typename?: 'Count', count?: number | null, key?: string | null } | null> | null, searchedjobCodes?: Array<{ __typename?: 'Count', count?: number | null, key?: string | null } | null> | null } | null };

export type GetUsageReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsageReportQuery = { __typename?: 'Query', getUsageReport?: { __typename?: 'ConsolidatedReport', seen?: Array<{ __typename?: 'UsageReport', email?: string | null, name?: string | null, ic?: string | null, psnumber?: string | null, designation?: string | null, phnumber?: string | null, candidate_code?: string | null, primary_email?: string | null, first_name?: string | null, last_name?: string | null, middle_name?: string | null, mobile_number?: string | null } | null> | null, down?: Array<{ __typename?: 'UsageReport', email?: string | null, name?: string | null, ic?: string | null, psnumber?: string | null, designation?: string | null, phnumber?: string | null, candidate_code?: string | null, primary_email?: string | null, first_name?: string | null, last_name?: string | null, middle_name?: string | null, mobile_number?: string | null } | null> | null } | null };

export type CreateFolderAndAddCandidatesMutationVariables = Exact<{
  name: Scalars['String']['input'];
  query?: InputMaybe<CandidateQuery>;
}>;


export type CreateFolderAndAddCandidatesMutation = { __typename?: 'Mutation', CreateFolderAndAddCandidates?: { __typename?: 'Folder', id: string, folderName: string } | null };

export type GetJobDownloadLinkQueryVariables = Exact<{
  code: Scalars['String']['input'];
  job_code?: InputMaybe<Scalars['String']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetJobDownloadLinkQuery = { __typename?: 'Query', getJobDownloadLink?: string | null };

export type GetTransfersQueryVariables = Exact<{
  start?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTransfersQuery = { __typename?: 'Query', getTransfers?: Array<{ __typename?: 'Transfers', candidates?: number | null, resumes?: number | null } | null> | null };


export const RegisterOrganizationDocument = gql`
    mutation RegisterOrganization($input: RegisterInput!) {
  RegisterOrganization(input: $input) {
    id
  }
}
    `;
export type RegisterOrganizationMutationFn = Apollo.MutationFunction<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>;

/**
 * __useRegisterOrganizationMutation__
 *
 * To run a mutation, you first call `useRegisterOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOrganizationMutation, { data, loading, error }] = useRegisterOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>(RegisterOrganizationDocument, options);
      }
export type RegisterOrganizationMutationHookResult = ReturnType<typeof useRegisterOrganizationMutation>;
export type RegisterOrganizationMutationResult = Apollo.MutationResult<RegisterOrganizationMutation>;
export type RegisterOrganizationMutationOptions = Apollo.BaseMutationOptions<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>;
export const UpdateOrganizationStatusDocument = gql`
    mutation UpdateOrganizationStatus($input: UpdateStatusInput!) {
  UpdateOrganizationStatus(input: $input) {
    id
  }
}
    `;
export type UpdateOrganizationStatusMutationFn = Apollo.MutationFunction<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>;

/**
 * __useUpdateOrganizationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationStatusMutation, { data, loading, error }] = useUpdateOrganizationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>(UpdateOrganizationStatusDocument, options);
      }
export type UpdateOrganizationStatusMutationHookResult = ReturnType<typeof useUpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationResult = Apollo.MutationResult<UpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>;
export const InviteOrganizationDocument = gql`
    mutation InviteOrganization($input: InviteOrganizationInput!) {
  InviteOrganization(input: $input) {
    id
    organizationName
    organizationEmail
    organizationWebsite
    status
  }
}
    `;
export type InviteOrganizationMutationFn = Apollo.MutationFunction<InviteOrganizationMutation, InviteOrganizationMutationVariables>;

/**
 * __useInviteOrganizationMutation__
 *
 * To run a mutation, you first call `useInviteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrganizationMutation, { data, loading, error }] = useInviteOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<InviteOrganizationMutation, InviteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteOrganizationMutation, InviteOrganizationMutationVariables>(InviteOrganizationDocument, options);
      }
export type InviteOrganizationMutationHookResult = ReturnType<typeof useInviteOrganizationMutation>;
export type InviteOrganizationMutationResult = Apollo.MutationResult<InviteOrganizationMutation>;
export type InviteOrganizationMutationOptions = Apollo.BaseMutationOptions<InviteOrganizationMutation, InviteOrganizationMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($input: UpdateRoleInput!) {
  UpdateUserRole(input: $input) {
    id
  }
}
    `;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: InviteUserInput!) {
  InviteUser(input: $input) {
    id
    name
    email
    password
    role
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  DeleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ActivateUserDocument = gql`
    mutation ActivateUser($input: ActivateInput!) {
  ActivateUser(input: $input) {
    id
  }
}
    `;
export type ActivateUserMutationFn = Apollo.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, options);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = Apollo.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = Apollo.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  ForgotPassword(input: $input)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordUserDocument = gql`
    mutation ResetPasswordUser($input: ResetPasswordInput!) {
  ResetPasswordUser(input: $input) {
    id
  }
}
    `;
export type ResetPasswordUserMutationFn = Apollo.MutationFunction<ResetPasswordUserMutation, ResetPasswordUserMutationVariables>;

/**
 * __useResetPasswordUserMutation__
 *
 * To run a mutation, you first call `useResetPasswordUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordUserMutation, { data, loading, error }] = useResetPasswordUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordUserMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordUserMutation, ResetPasswordUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordUserMutation, ResetPasswordUserMutationVariables>(ResetPasswordUserDocument, options);
      }
export type ResetPasswordUserMutationHookResult = ReturnType<typeof useResetPasswordUserMutation>;
export type ResetPasswordUserMutationResult = Apollo.MutationResult<ResetPasswordUserMutation>;
export type ResetPasswordUserMutationOptions = Apollo.BaseMutationOptions<ResetPasswordUserMutation, ResetPasswordUserMutationVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($name: String!) {
  CreateFolder(name: $name) {
    id
    folderName
  }
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($folderId: String!) {
  DeleteFolder(folderId: $folderId) {
    id
  }
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const AddCandidatesToFolderDocument = gql`
    mutation AddCandidatesToFolder($folderId: String!, $candidateId: [String]) {
  AddCandidatesToFolder(folderId: $folderId, candidateId: $candidateId)
}
    `;
export type AddCandidatesToFolderMutationFn = Apollo.MutationFunction<AddCandidatesToFolderMutation, AddCandidatesToFolderMutationVariables>;

/**
 * __useAddCandidatesToFolderMutation__
 *
 * To run a mutation, you first call `useAddCandidatesToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidatesToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidatesToFolderMutation, { data, loading, error }] = useAddCandidatesToFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useAddCandidatesToFolderMutation(baseOptions?: Apollo.MutationHookOptions<AddCandidatesToFolderMutation, AddCandidatesToFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCandidatesToFolderMutation, AddCandidatesToFolderMutationVariables>(AddCandidatesToFolderDocument, options);
      }
export type AddCandidatesToFolderMutationHookResult = ReturnType<typeof useAddCandidatesToFolderMutation>;
export type AddCandidatesToFolderMutationResult = Apollo.MutationResult<AddCandidatesToFolderMutation>;
export type AddCandidatesToFolderMutationOptions = Apollo.BaseMutationOptions<AddCandidatesToFolderMutation, AddCandidatesToFolderMutationVariables>;
export const AddCandidatesToFolderForQueryDocument = gql`
    mutation AddCandidatesToFolderForQuery($folderId: String!, $query: CandidateQuery) {
  AddCandidatesToFolderForQuery(folderId: $folderId, query: $query)
}
    `;
export type AddCandidatesToFolderForQueryMutationFn = Apollo.MutationFunction<AddCandidatesToFolderForQueryMutation, AddCandidatesToFolderForQueryMutationVariables>;

/**
 * __useAddCandidatesToFolderForQueryMutation__
 *
 * To run a mutation, you first call `useAddCandidatesToFolderForQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidatesToFolderForQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidatesToFolderForQueryMutation, { data, loading, error }] = useAddCandidatesToFolderForQueryMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddCandidatesToFolderForQueryMutation(baseOptions?: Apollo.MutationHookOptions<AddCandidatesToFolderForQueryMutation, AddCandidatesToFolderForQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCandidatesToFolderForQueryMutation, AddCandidatesToFolderForQueryMutationVariables>(AddCandidatesToFolderForQueryDocument, options);
      }
export type AddCandidatesToFolderForQueryMutationHookResult = ReturnType<typeof useAddCandidatesToFolderForQueryMutation>;
export type AddCandidatesToFolderForQueryMutationResult = Apollo.MutationResult<AddCandidatesToFolderForQueryMutation>;
export type AddCandidatesToFolderForQueryMutationOptions = Apollo.BaseMutationOptions<AddCandidatesToFolderForQueryMutation, AddCandidatesToFolderForQueryMutationVariables>;
export const DeleteCandidateToFolderDocument = gql`
    mutation DeleteCandidateToFolder($folderId: String!, $candidateId: String!) {
  DeleteCandidateToFolder(folderId: $folderId, candidateId: $candidateId) {
    id
  }
}
    `;
export type DeleteCandidateToFolderMutationFn = Apollo.MutationFunction<DeleteCandidateToFolderMutation, DeleteCandidateToFolderMutationVariables>;

/**
 * __useDeleteCandidateToFolderMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateToFolderMutation, { data, loading, error }] = useDeleteCandidateToFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useDeleteCandidateToFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCandidateToFolderMutation, DeleteCandidateToFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCandidateToFolderMutation, DeleteCandidateToFolderMutationVariables>(DeleteCandidateToFolderDocument, options);
      }
export type DeleteCandidateToFolderMutationHookResult = ReturnType<typeof useDeleteCandidateToFolderMutation>;
export type DeleteCandidateToFolderMutationResult = Apollo.MutationResult<DeleteCandidateToFolderMutation>;
export type DeleteCandidateToFolderMutationOptions = Apollo.BaseMutationOptions<DeleteCandidateToFolderMutation, DeleteCandidateToFolderMutationVariables>;
export const AddCandidateToSeenDocument = gql`
    mutation AddCandidateToSeen($candidateId: String!) {
  AddCandidateToSeen(candidateId: $candidateId)
}
    `;
export type AddCandidateToSeenMutationFn = Apollo.MutationFunction<AddCandidateToSeenMutation, AddCandidateToSeenMutationVariables>;

/**
 * __useAddCandidateToSeenMutation__
 *
 * To run a mutation, you first call `useAddCandidateToSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidateToSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidateToSeenMutation, { data, loading, error }] = useAddCandidateToSeenMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useAddCandidateToSeenMutation(baseOptions?: Apollo.MutationHookOptions<AddCandidateToSeenMutation, AddCandidateToSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCandidateToSeenMutation, AddCandidateToSeenMutationVariables>(AddCandidateToSeenDocument, options);
      }
export type AddCandidateToSeenMutationHookResult = ReturnType<typeof useAddCandidateToSeenMutation>;
export type AddCandidateToSeenMutationResult = Apollo.MutationResult<AddCandidateToSeenMutation>;
export type AddCandidateToSeenMutationOptions = Apollo.BaseMutationOptions<AddCandidateToSeenMutation, AddCandidateToSeenMutationVariables>;
export const AddCandidateToDownloadedDocument = gql`
    mutation AddCandidateToDownloaded($candidateId: String!, $job_code: String, $global: Boolean) {
  AddCandidateToDownloaded(
    candidateId: $candidateId
    job_code: $job_code
    global: $global
  )
}
    `;
export type AddCandidateToDownloadedMutationFn = Apollo.MutationFunction<AddCandidateToDownloadedMutation, AddCandidateToDownloadedMutationVariables>;

/**
 * __useAddCandidateToDownloadedMutation__
 *
 * To run a mutation, you first call `useAddCandidateToDownloadedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidateToDownloadedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidateToDownloadedMutation, { data, loading, error }] = useAddCandidateToDownloadedMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      job_code: // value for 'job_code'
 *      global: // value for 'global'
 *   },
 * });
 */
export function useAddCandidateToDownloadedMutation(baseOptions?: Apollo.MutationHookOptions<AddCandidateToDownloadedMutation, AddCandidateToDownloadedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCandidateToDownloadedMutation, AddCandidateToDownloadedMutationVariables>(AddCandidateToDownloadedDocument, options);
      }
export type AddCandidateToDownloadedMutationHookResult = ReturnType<typeof useAddCandidateToDownloadedMutation>;
export type AddCandidateToDownloadedMutationResult = Apollo.MutationResult<AddCandidateToDownloadedMutation>;
export type AddCandidateToDownloadedMutationOptions = Apollo.BaseMutationOptions<AddCandidateToDownloadedMutation, AddCandidateToDownloadedMutationVariables>;
export const AddCandidateToEmailedDocument = gql`
    mutation AddCandidateToEmailed($candidateId: String!) {
  AddCandidateToEmailed(candidateId: $candidateId)
}
    `;
export type AddCandidateToEmailedMutationFn = Apollo.MutationFunction<AddCandidateToEmailedMutation, AddCandidateToEmailedMutationVariables>;

/**
 * __useAddCandidateToEmailedMutation__
 *
 * To run a mutation, you first call `useAddCandidateToEmailedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCandidateToEmailedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCandidateToEmailedMutation, { data, loading, error }] = useAddCandidateToEmailedMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useAddCandidateToEmailedMutation(baseOptions?: Apollo.MutationHookOptions<AddCandidateToEmailedMutation, AddCandidateToEmailedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCandidateToEmailedMutation, AddCandidateToEmailedMutationVariables>(AddCandidateToEmailedDocument, options);
      }
export type AddCandidateToEmailedMutationHookResult = ReturnType<typeof useAddCandidateToEmailedMutation>;
export type AddCandidateToEmailedMutationResult = Apollo.MutationResult<AddCandidateToEmailedMutation>;
export type AddCandidateToEmailedMutationOptions = Apollo.BaseMutationOptions<AddCandidateToEmailedMutation, AddCandidateToEmailedMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($input: LoginInput!) {
  LoginUser(input: $input) {
    userJwtToken {
      token
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const AddCommentDocument = gql`
    mutation AddComment($candidateId: String!, $comment: String!) {
  AddComment(candidateId: $candidateId, comment: $comment) {
    id
    comment
    userEmail
  }
}
    `;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const GetUserDataDocument = gql`
    query getUserData {
  getUserData {
    id
    email
    name
    role
    organization {
      id
      organizationName
      organizationEmail
      organizationWebsite
      status
    }
    folders {
      id
      folderName
    }
  }
}
    `;

/**
 * __useGetUserDataQuery__
 *
 * To run a query within a React component, call `useGetUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDataQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
      }
export function useGetUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
        }
export function useGetUserDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
        }
export type GetUserDataQueryHookResult = ReturnType<typeof useGetUserDataQuery>;
export type GetUserDataLazyQueryHookResult = ReturnType<typeof useGetUserDataLazyQuery>;
export type GetUserDataSuspenseQueryHookResult = ReturnType<typeof useGetUserDataSuspenseQuery>;
export type GetUserDataQueryResult = Apollo.QueryResult<GetUserDataQuery, GetUserDataQueryVariables>;
export const GetJobDocument = gql`
    query getJob($query: String, $page: Int) {
  getJob(query: $query, page: $page) {
    count
    jobs {
      job_code
      job_title
      location
      ctc_range
      experience_in_years
      no_of_openings
      closing_date
      start_date
    }
  }
}
    `;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions?: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
      }
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export function useGetJobSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
        }
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobSuspenseQueryHookResult = ReturnType<typeof useGetJobSuspenseQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const GetJobsDocument = gql`
    query getJobs($page: Int) {
  getJobs(page: $page) {
    count
    jobs {
      job_code
      job_title
      location
      ctc_range
      experience_in_years
      no_of_openings
      closing_date
      start_date
    }
  }
}
    `;

/**
 * __useGetJobsQuery__
 *
 * To run a query within a React component, call `useGetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
      }
export function useGetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export function useGetJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobsQuery, GetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobsQuery, GetJobsQueryVariables>(GetJobsDocument, options);
        }
export type GetJobsQueryHookResult = ReturnType<typeof useGetJobsQuery>;
export type GetJobsLazyQueryHookResult = ReturnType<typeof useGetJobsLazyQuery>;
export type GetJobsSuspenseQueryHookResult = ReturnType<typeof useGetJobsSuspenseQuery>;
export type GetJobsQueryResult = Apollo.QueryResult<GetJobsQuery, GetJobsQueryVariables>;
export const GetJobDetailsDocument = gql`
    query getJobDetails($id: String) {
  getJobDetails(id: $id) {
    appointment_type
    band
    closing_date
    country
    ctc_range
    discipline
    employment_type
    experience_in_years
    function
    hiring_manager
    industryname
    job_code
    job_title
    location
    max_grade_cadre
    minimum_qualification
    no_of_openings
    posting_location
    requistion_type
    role
    skills
    start_date
    state
    sub_function
    tat
    working_language
  }
}
    `;

/**
 * __useGetJobDetailsQuery__
 *
 * To run a query within a React component, call `useGetJobDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetJobDetailsQuery, GetJobDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(GetJobDetailsDocument, options);
      }
export function useGetJobDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobDetailsQuery, GetJobDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(GetJobDetailsDocument, options);
        }
export function useGetJobDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobDetailsQuery, GetJobDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobDetailsQuery, GetJobDetailsQueryVariables>(GetJobDetailsDocument, options);
        }
export type GetJobDetailsQueryHookResult = ReturnType<typeof useGetJobDetailsQuery>;
export type GetJobDetailsLazyQueryHookResult = ReturnType<typeof useGetJobDetailsLazyQuery>;
export type GetJobDetailsSuspenseQueryHookResult = ReturnType<typeof useGetJobDetailsSuspenseQuery>;
export type GetJobDetailsQueryResult = Apollo.QueryResult<GetJobDetailsQuery, GetJobDetailsQueryVariables>;
export const GetJobDescriptionDocument = gql`
    query getJobDescription($id: String) {
  getJobDetails(id: $id) {
    job_description
  }
}
    `;

/**
 * __useGetJobDescriptionQuery__
 *
 * To run a query within a React component, call `useGetJobDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobDescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetJobDescriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>(GetJobDescriptionDocument, options);
      }
export function useGetJobDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>(GetJobDescriptionDocument, options);
        }
export function useGetJobDescriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>(GetJobDescriptionDocument, options);
        }
export type GetJobDescriptionQueryHookResult = ReturnType<typeof useGetJobDescriptionQuery>;
export type GetJobDescriptionLazyQueryHookResult = ReturnType<typeof useGetJobDescriptionLazyQuery>;
export type GetJobDescriptionSuspenseQueryHookResult = ReturnType<typeof useGetJobDescriptionSuspenseQuery>;
export type GetJobDescriptionQueryResult = Apollo.QueryResult<GetJobDescriptionQuery, GetJobDescriptionQueryVariables>;
export const GetCandidatesDocument = gql`
    query getCandidates($query: CandidateQuery, $page: Int) {
  getCandidates(query: $query, page: $page) {
    count
    candidates {
      candidate_code
      title
      first_name
      middle_name
      last_name
      fresher
      gender
      languages
      mobile_number
      primary_email
      skills
      total_work_experience_years
      candidate_age
      state
    }
  }
}
    `;

/**
 * __useGetCandidatesQuery__
 *
 * To run a query within a React component, call `useGetCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCandidatesQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidatesQuery, GetCandidatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidatesQuery, GetCandidatesQueryVariables>(GetCandidatesDocument, options);
      }
export function useGetCandidatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidatesQuery, GetCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidatesQuery, GetCandidatesQueryVariables>(GetCandidatesDocument, options);
        }
export function useGetCandidatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCandidatesQuery, GetCandidatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCandidatesQuery, GetCandidatesQueryVariables>(GetCandidatesDocument, options);
        }
export type GetCandidatesQueryHookResult = ReturnType<typeof useGetCandidatesQuery>;
export type GetCandidatesLazyQueryHookResult = ReturnType<typeof useGetCandidatesLazyQuery>;
export type GetCandidatesSuspenseQueryHookResult = ReturnType<typeof useGetCandidatesSuspenseQuery>;
export type GetCandidatesQueryResult = Apollo.QueryResult<GetCandidatesQuery, GetCandidatesQueryVariables>;
export const GetCandidateDetailsDocument = gql`
    query getCandidateDetails($id: String, $job_code: String, $global: Boolean) {
  getCandidateDetails(id: $id, job_code: $job_code, global: $global) {
    candidate_code
    title
    first_name
    middle_name
    last_name
    fresher
    gender
    languages
    mobile_number
    notice_period
    primary_email
    skills
    source_code
    source_type
    source_name
    total_work_experience_years
    candidate_age
    education {
      qualification
      institute
      start_date
      end_date
      grade_percentage
    }
    work_experience {
      company
      still_working_here
      ctc
      start_date
      end_date
    }
    functional_area
    permanent_address
    current_address
    job_codes
    job_title
    file_name
  }
}
    `;

/**
 * __useGetCandidateDetailsQuery__
 *
 * To run a query within a React component, call `useGetCandidateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      job_code: // value for 'job_code'
 *      global: // value for 'global'
 *   },
 * });
 */
export function useGetCandidateDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>(GetCandidateDetailsDocument, options);
      }
export function useGetCandidateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>(GetCandidateDetailsDocument, options);
        }
export function useGetCandidateDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>(GetCandidateDetailsDocument, options);
        }
export type GetCandidateDetailsQueryHookResult = ReturnType<typeof useGetCandidateDetailsQuery>;
export type GetCandidateDetailsLazyQueryHookResult = ReturnType<typeof useGetCandidateDetailsLazyQuery>;
export type GetCandidateDetailsSuspenseQueryHookResult = ReturnType<typeof useGetCandidateDetailsSuspenseQuery>;
export type GetCandidateDetailsQueryResult = Apollo.QueryResult<GetCandidateDetailsQuery, GetCandidateDetailsQueryVariables>;
export const GetCandidateResumeDocument = gql`
    query getCandidateResume($id: String) {
  getCandidateDetails(id: $id) {
    raw_string
  }
}
    `;

/**
 * __useGetCandidateResumeQuery__
 *
 * To run a query within a React component, call `useGetCandidateResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateResumeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCandidateResumeQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>(GetCandidateResumeDocument, options);
      }
export function useGetCandidateResumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>(GetCandidateResumeDocument, options);
        }
export function useGetCandidateResumeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>(GetCandidateResumeDocument, options);
        }
export type GetCandidateResumeQueryHookResult = ReturnType<typeof useGetCandidateResumeQuery>;
export type GetCandidateResumeLazyQueryHookResult = ReturnType<typeof useGetCandidateResumeLazyQuery>;
export type GetCandidateResumeSuspenseQueryHookResult = ReturnType<typeof useGetCandidateResumeSuspenseQuery>;
export type GetCandidateResumeQueryResult = Apollo.QueryResult<GetCandidateResumeQuery, GetCandidateResumeQueryVariables>;
export const GetCandidateResumeFileDocument = gql`
    query getCandidateResumeFile($id: String) {
  getCandidateDetails(id: $id) {
    base64_string
  }
}
    `;

/**
 * __useGetCandidateResumeFileQuery__
 *
 * To run a query within a React component, call `useGetCandidateResumeFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateResumeFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateResumeFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCandidateResumeFileQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>(GetCandidateResumeFileDocument, options);
      }
export function useGetCandidateResumeFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>(GetCandidateResumeFileDocument, options);
        }
export function useGetCandidateResumeFileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>(GetCandidateResumeFileDocument, options);
        }
export type GetCandidateResumeFileQueryHookResult = ReturnType<typeof useGetCandidateResumeFileQuery>;
export type GetCandidateResumeFileLazyQueryHookResult = ReturnType<typeof useGetCandidateResumeFileLazyQuery>;
export type GetCandidateResumeFileSuspenseQueryHookResult = ReturnType<typeof useGetCandidateResumeFileSuspenseQuery>;
export type GetCandidateResumeFileQueryResult = Apollo.QueryResult<GetCandidateResumeFileQuery, GetCandidateResumeFileQueryVariables>;
export const GetCandidatesbyFolderDocument = gql`
    query getCandidatesbyFolder($id: String, $page: Int) {
  getCandidatesbyFolder(id: $id, page: $page) {
    count
    candidates {
      candidate_code
      title
      first_name
      middle_name
      last_name
      fresher
      gender
      languages
      mobile_number
      primary_email
      skills
      total_work_experience_years
      candidate_age
    }
  }
}
    `;

/**
 * __useGetCandidatesbyFolderQuery__
 *
 * To run a query within a React component, call `useGetCandidatesbyFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesbyFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesbyFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCandidatesbyFolderQuery(baseOptions?: Apollo.QueryHookOptions<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>(GetCandidatesbyFolderDocument, options);
      }
export function useGetCandidatesbyFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>(GetCandidatesbyFolderDocument, options);
        }
export function useGetCandidatesbyFolderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>(GetCandidatesbyFolderDocument, options);
        }
export type GetCandidatesbyFolderQueryHookResult = ReturnType<typeof useGetCandidatesbyFolderQuery>;
export type GetCandidatesbyFolderLazyQueryHookResult = ReturnType<typeof useGetCandidatesbyFolderLazyQuery>;
export type GetCandidatesbyFolderSuspenseQueryHookResult = ReturnType<typeof useGetCandidatesbyFolderSuspenseQuery>;
export type GetCandidatesbyFolderQueryResult = Apollo.QueryResult<GetCandidatesbyFolderQuery, GetCandidatesbyFolderQueryVariables>;
export const GetResumeDocument = gql`
    query getResume($id: String) {
  getResume
}
    `;

/**
 * __useGetResumeQuery__
 *
 * To run a query within a React component, call `useGetResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResumeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetResumeQuery(baseOptions?: Apollo.QueryHookOptions<GetResumeQuery, GetResumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResumeQuery, GetResumeQueryVariables>(GetResumeDocument, options);
      }
export function useGetResumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResumeQuery, GetResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResumeQuery, GetResumeQueryVariables>(GetResumeDocument, options);
        }
export function useGetResumeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetResumeQuery, GetResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetResumeQuery, GetResumeQueryVariables>(GetResumeDocument, options);
        }
export type GetResumeQueryHookResult = ReturnType<typeof useGetResumeQuery>;
export type GetResumeLazyQueryHookResult = ReturnType<typeof useGetResumeLazyQuery>;
export type GetResumeSuspenseQueryHookResult = ReturnType<typeof useGetResumeSuspenseQuery>;
export type GetResumeQueryResult = Apollo.QueryResult<GetResumeQuery, GetResumeQueryVariables>;
export const GetFolderExcelDataDocument = gql`
    query getFolderExcelData($folder: String) {
  getFolderExcelData
}
    `;

/**
 * __useGetFolderExcelDataQuery__
 *
 * To run a query within a React component, call `useGetFolderExcelDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderExcelDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderExcelDataQuery({
 *   variables: {
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useGetFolderExcelDataQuery(baseOptions?: Apollo.QueryHookOptions<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>(GetFolderExcelDataDocument, options);
      }
export function useGetFolderExcelDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>(GetFolderExcelDataDocument, options);
        }
export function useGetFolderExcelDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>(GetFolderExcelDataDocument, options);
        }
export type GetFolderExcelDataQueryHookResult = ReturnType<typeof useGetFolderExcelDataQuery>;
export type GetFolderExcelDataLazyQueryHookResult = ReturnType<typeof useGetFolderExcelDataLazyQuery>;
export type GetFolderExcelDataSuspenseQueryHookResult = ReturnType<typeof useGetFolderExcelDataSuspenseQuery>;
export type GetFolderExcelDataQueryResult = Apollo.QueryResult<GetFolderExcelDataQuery, GetFolderExcelDataQueryVariables>;
export const GetOrganizationsDocument = gql`
    query getOrganizations {
  getOrganizations {
    id
    organizationName
    organizationEmail
    organizationWebsite
    status
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export function useGetOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationsSuspenseQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetUserByOrganizationDocument = gql`
    query getUserByOrganization {
  getUserByOrganization {
    id
    name
    email
    role
  }
}
    `;

/**
 * __useGetUserByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUserByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserByOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>(GetUserByOrganizationDocument, options);
      }
export function useGetUserByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>(GetUserByOrganizationDocument, options);
        }
export function useGetUserByOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>(GetUserByOrganizationDocument, options);
        }
export type GetUserByOrganizationQueryHookResult = ReturnType<typeof useGetUserByOrganizationQuery>;
export type GetUserByOrganizationLazyQueryHookResult = ReturnType<typeof useGetUserByOrganizationLazyQuery>;
export type GetUserByOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetUserByOrganizationSuspenseQuery>;
export type GetUserByOrganizationQueryResult = Apollo.QueryResult<GetUserByOrganizationQuery, GetUserByOrganizationQueryVariables>;
export const GetDownloadLinkDocument = gql`
    query getDownloadLink($ids: [String], $job_code: String, $global: Boolean) {
  getDownloadLink(ids: $ids, job_code: $job_code, global: $global)
}
    `;

/**
 * __useGetDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadLinkQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      job_code: // value for 'job_code'
 *      global: // value for 'global'
 *   },
 * });
 */
export function useGetDownloadLinkQuery(baseOptions?: Apollo.QueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, options);
      }
export function useGetDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, options);
        }
export function useGetDownloadLinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, options);
        }
export type GetDownloadLinkQueryHookResult = ReturnType<typeof useGetDownloadLinkQuery>;
export type GetDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetDownloadLinkLazyQuery>;
export type GetDownloadLinkSuspenseQueryHookResult = ReturnType<typeof useGetDownloadLinkSuspenseQuery>;
export type GetDownloadLinkQueryResult = Apollo.QueryResult<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>;
export const GetFolderLinkDocument = gql`
    query getFolderLink($id: String!) {
  getFolderLink(id: $id)
}
    `;

/**
 * __useGetFolderLinkQuery__
 *
 * To run a query within a React component, call `useGetFolderLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFolderLinkQuery(baseOptions: Apollo.QueryHookOptions<GetFolderLinkQuery, GetFolderLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFolderLinkQuery, GetFolderLinkQueryVariables>(GetFolderLinkDocument, options);
      }
export function useGetFolderLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFolderLinkQuery, GetFolderLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFolderLinkQuery, GetFolderLinkQueryVariables>(GetFolderLinkDocument, options);
        }
export function useGetFolderLinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetFolderLinkQuery, GetFolderLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFolderLinkQuery, GetFolderLinkQueryVariables>(GetFolderLinkDocument, options);
        }
export type GetFolderLinkQueryHookResult = ReturnType<typeof useGetFolderLinkQuery>;
export type GetFolderLinkLazyQueryHookResult = ReturnType<typeof useGetFolderLinkLazyQuery>;
export type GetFolderLinkSuspenseQueryHookResult = ReturnType<typeof useGetFolderLinkSuspenseQuery>;
export type GetFolderLinkQueryResult = Apollo.QueryResult<GetFolderLinkQuery, GetFolderLinkQueryVariables>;
export const GetCommentsDocument = gql`
    query getComments($id: String!) {
  getComments(id: $id) {
    id
    comment
    userEmail
  }
}
    `;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export function useGetCommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsSuspenseQueryHookResult = ReturnType<typeof useGetCommentsSuspenseQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetCandidatesCountDocument = gql`
    query getCandidatesCount($code: String!) {
  getCandidatesCount(code: $code)
}
    `;

/**
 * __useGetCandidatesCountQuery__
 *
 * To run a query within a React component, call `useGetCandidatesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidatesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidatesCountQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCandidatesCountQuery(baseOptions: Apollo.QueryHookOptions<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>(GetCandidatesCountDocument, options);
      }
export function useGetCandidatesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>(GetCandidatesCountDocument, options);
        }
export function useGetCandidatesCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>(GetCandidatesCountDocument, options);
        }
export type GetCandidatesCountQueryHookResult = ReturnType<typeof useGetCandidatesCountQuery>;
export type GetCandidatesCountLazyQueryHookResult = ReturnType<typeof useGetCandidatesCountLazyQuery>;
export type GetCandidatesCountSuspenseQueryHookResult = ReturnType<typeof useGetCandidatesCountSuspenseQuery>;
export type GetCandidatesCountQueryResult = Apollo.QueryResult<GetCandidatesCountQuery, GetCandidatesCountQueryVariables>;
export const GetMetricsDocument = gql`
    query getMetrics($userId: String, $start: String!, $end: String!) {
  getMetrics(userId: $userId, start: $start, end: $end) {
    viewed {
      _count
      date
    }
    downloaded {
      _count
      date
    }
    searched {
      _count
      date
    }
    keywords {
      count
      key
    }
    searchedjobCodesGlobal {
      count
      key
    }
    searchedjobCodes {
      count
      key
    }
    totalDownloaded
    totalSearched
    totalViewed
  }
}
    `;

/**
 * __useGetMetricsQuery__
 *
 * To run a query within a React component, call `useGetMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricsQuery, GetMetricsQueryVariables>(GetMetricsDocument, options);
      }
export function useGetMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricsQuery, GetMetricsQueryVariables>(GetMetricsDocument, options);
        }
export function useGetMetricsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMetricsQuery, GetMetricsQueryVariables>(GetMetricsDocument, options);
        }
export type GetMetricsQueryHookResult = ReturnType<typeof useGetMetricsQuery>;
export type GetMetricsLazyQueryHookResult = ReturnType<typeof useGetMetricsLazyQuery>;
export type GetMetricsSuspenseQueryHookResult = ReturnType<typeof useGetMetricsSuspenseQuery>;
export type GetMetricsQueryResult = Apollo.QueryResult<GetMetricsQuery, GetMetricsQueryVariables>;
export const GetUsageReportDocument = gql`
    query GetUsageReport {
  getUsageReport {
    seen {
      email
      name
      ic
      psnumber
      designation
      phnumber
      candidate_code
      primary_email
      first_name
      last_name
      middle_name
      mobile_number
    }
    down {
      email
      name
      ic
      psnumber
      designation
      phnumber
      candidate_code
      primary_email
      first_name
      last_name
      middle_name
      mobile_number
    }
  }
}
    `;

/**
 * __useGetUsageReportQuery__
 *
 * To run a query within a React component, call `useGetUsageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsageReportQuery(baseOptions?: Apollo.QueryHookOptions<GetUsageReportQuery, GetUsageReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsageReportQuery, GetUsageReportQueryVariables>(GetUsageReportDocument, options);
      }
export function useGetUsageReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsageReportQuery, GetUsageReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsageReportQuery, GetUsageReportQueryVariables>(GetUsageReportDocument, options);
        }
export function useGetUsageReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsageReportQuery, GetUsageReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsageReportQuery, GetUsageReportQueryVariables>(GetUsageReportDocument, options);
        }
export type GetUsageReportQueryHookResult = ReturnType<typeof useGetUsageReportQuery>;
export type GetUsageReportLazyQueryHookResult = ReturnType<typeof useGetUsageReportLazyQuery>;
export type GetUsageReportSuspenseQueryHookResult = ReturnType<typeof useGetUsageReportSuspenseQuery>;
export type GetUsageReportQueryResult = Apollo.QueryResult<GetUsageReportQuery, GetUsageReportQueryVariables>;
export const CreateFolderAndAddCandidatesDocument = gql`
    mutation createFolderAndAddCandidates($name: String!, $query: CandidateQuery) {
  CreateFolderAndAddCandidates(name: $name, query: $query) {
    id
    folderName
  }
}
    `;
export type CreateFolderAndAddCandidatesMutationFn = Apollo.MutationFunction<CreateFolderAndAddCandidatesMutation, CreateFolderAndAddCandidatesMutationVariables>;

/**
 * __useCreateFolderAndAddCandidatesMutation__
 *
 * To run a mutation, you first call `useCreateFolderAndAddCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderAndAddCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderAndAddCandidatesMutation, { data, loading, error }] = useCreateFolderAndAddCandidatesMutation({
 *   variables: {
 *      name: // value for 'name'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCreateFolderAndAddCandidatesMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderAndAddCandidatesMutation, CreateFolderAndAddCandidatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFolderAndAddCandidatesMutation, CreateFolderAndAddCandidatesMutationVariables>(CreateFolderAndAddCandidatesDocument, options);
      }
export type CreateFolderAndAddCandidatesMutationHookResult = ReturnType<typeof useCreateFolderAndAddCandidatesMutation>;
export type CreateFolderAndAddCandidatesMutationResult = Apollo.MutationResult<CreateFolderAndAddCandidatesMutation>;
export type CreateFolderAndAddCandidatesMutationOptions = Apollo.BaseMutationOptions<CreateFolderAndAddCandidatesMutation, CreateFolderAndAddCandidatesMutationVariables>;
export const GetJobDownloadLinkDocument = gql`
    query getJobDownloadLink($code: String!, $job_code: String, $global: Boolean) {
  getJobDownloadLink(code: $code, job_code: $job_code, global: $global)
}
    `;

/**
 * __useGetJobDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetJobDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobDownloadLinkQuery({
 *   variables: {
 *      code: // value for 'code'
 *      job_code: // value for 'job_code'
 *      global: // value for 'global'
 *   },
 * });
 */
export function useGetJobDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>(GetJobDownloadLinkDocument, options);
      }
export function useGetJobDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>(GetJobDownloadLinkDocument, options);
        }
export function useGetJobDownloadLinkSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>(GetJobDownloadLinkDocument, options);
        }
export type GetJobDownloadLinkQueryHookResult = ReturnType<typeof useGetJobDownloadLinkQuery>;
export type GetJobDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetJobDownloadLinkLazyQuery>;
export type GetJobDownloadLinkSuspenseQueryHookResult = ReturnType<typeof useGetJobDownloadLinkSuspenseQuery>;
export type GetJobDownloadLinkQueryResult = Apollo.QueryResult<GetJobDownloadLinkQuery, GetJobDownloadLinkQueryVariables>;
export const GetTransfersDocument = gql`
    query getTransfers($start: String, $end: String) {
  getTransfers(start: $start, end: $end) {
    candidates
    resumes
  }
}
    `;

/**
 * __useGetTransfersQuery__
 *
 * To run a query within a React component, call `useGetTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransfersQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetTransfersQuery(baseOptions?: Apollo.QueryHookOptions<GetTransfersQuery, GetTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransfersQuery, GetTransfersQueryVariables>(GetTransfersDocument, options);
      }
export function useGetTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransfersQuery, GetTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransfersQuery, GetTransfersQueryVariables>(GetTransfersDocument, options);
        }
export function useGetTransfersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTransfersQuery, GetTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTransfersQuery, GetTransfersQueryVariables>(GetTransfersDocument, options);
        }
export type GetTransfersQueryHookResult = ReturnType<typeof useGetTransfersQuery>;
export type GetTransfersLazyQueryHookResult = ReturnType<typeof useGetTransfersLazyQuery>;
export type GetTransfersSuspenseQueryHookResult = ReturnType<typeof useGetTransfersSuspenseQuery>;
export type GetTransfersQueryResult = Apollo.QueryResult<GetTransfersQuery, GetTransfersQueryVariables>;