import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Count, Maybe } from "../generated/graphql";
import { FC } from "react";

interface ITableDataProps {
  data: Maybe<Maybe<Count>[]> | undefined;
  label1: string;
  label2: string;
}

const TableData: FC<ITableDataProps> = ({ data, label1, label2 }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{label1}</TableCell>
            <TableCell align="right">{label2}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              key={row?.key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.key}
              </TableCell>
              <TableCell align="right">{row?.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableData;
