import {
  CircularProgress,
  Box,
  Container,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { INotification } from "../../interfaces/General";
import { Notification } from "../../molecules/Notification";
import { useGetTransfersLazyQuery, Transfers } from "../../generated/graphql";

const OverviewView = () => {
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfer] = useState<Transfers[]>([]);
  const [notifcation, setNotification] = useState<INotification>({
    message: "",
    open: false,
    type: "info",
  });
  const [getTransfers] = useGetTransfersLazyQuery({
    onCompleted(data) {
      setTransfer(data.getTransfers as Transfers[]);
    },
  });
  useEffect(() => {
    getTransfers();
  }, []);
  return (
    <>
      <Notification {...notifcation} setOpen={setNotification} />
      <Container>
        <div className="p-[25px]">
          <div className="text-[#3F2E3C] text-center text-5xl p-5">
            Welcome to Lokibots Talent Acquisition Platform
          </div>
          <div className="text-black text-center text-lg py-2">
            Revolutionize Your Hiring Process with Lokibots
          </div>
          <div className={`text-["#3F2E3C"] text-center opacity-50 text-base`}>
            At Lokibots, we understand the challenges of talent acquisition in
            today's competitive landscape. Our Talent Acquisition Platform is
            designed to streamline and enhance your hiring process, saving you
            time and resources while ensuring you find the right candidates for
            your team.
          </div>
          <Stack
            direction="row"
            className="gap-[20px] justify-between mt-[50px]"
          >
            <div className="min-h-[350px] w-full p-[20px] border border-solid border-black rounded-lg shadow-lg bg-white justify-between">
              {" "}
              <div className="text-black opacity-50 text-center text-5xl font-medium">
                Candidates Available
              </div>
              <div className="mt-[10%] flex justify-center place-items-center">
                {!loading ? (
                  <div
                    className={`text-primary-main text-center text-5xl opacity-80`}
                  >
                    1000000+
                  </div>
                ) : (
                  <CircularProgress size={48} color="inherit" />
                )}
              </div>
            </div>
            <div className="min-h-[350px] min-w-[30%] p-[20px] border border-solid border-black rounded-lg shadow-lg bg-white justify-between">
              {" "}
              <div className="text-black opacity-50 text-center text-5xl font-medium">
                Daily Transfers
              </div>
              <div className="mt-4 flex justify-center place-items-center">
                {!loading ? (
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            <b>Candidates</b>
                          </TableCell>
                          <TableCell align="center">
                            <b>Resumes</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transfers?.map((row) => (
                          <TableRow
                            key={row?.candidates}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row?.candidates}
                            </TableCell>
                            <TableCell align="center">{row?.resumes}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <CircularProgress size={48} color="inherit" />
                )}
              </div>
            </div>
          </Stack>
        </div>
      </Container>
    </>
  );
};

export default OverviewView;
