const pgDegree = [
  {
    Degree: "Master of Business Administration or MBA",
    Majors: [
      "Finance",
      "Marketing",
      "Human Resources",
      "Operations Management",
      "Information Technology",
    ],
  },
  {
    Degree: "Master of Technology or MT",
    Majors: [
      "Computer Science",
      "Mechanical",
      "Electrical",
      "Civil",
      "Chemical",
      "Biotechnology",
    ],
  },
  {
    Degree: "Master of Science or MS",
    Majors: [
      "Physics",
      "Chemistry",
      "Biology",
      "Mathematics",
      "Environmental Science",
      "Biotechnology",
    ],
  },
  {
    Degree: "Master of Arts or MA",
    Majors: [
      "English",
      "History",
      "Political Science",
      "Economics",
      "Sociology",
      "Psychology",
    ],
  },
  {
    Degree: "Master of Computer Applications or MCA",
    Majors: [
      "Application Software",
      "Hardware Technology",
      "Management Information Systems",
      "Internet",
      "Software Development",
      "Systems Management",
    ],
  },
  {
    Degree: "Master of Pharmacy or MP",
    Majors: [
      "Pharmaceutics",
      "Pharmacology",
      "Pharmaceutical Chemistry",
      "Pharmacognosy",
      "Quality Assurance",
    ],
  },
  {
    Degree: "Master of Education or ME",
    Majors: [
      "Educational Technology",
      "Educational Management",
      "Curriculum and Instruction",
      "Special Education",
      "Educational Planning",
    ],
  },
  {
    Degree: "Master of Laws or LLM",
    Majors: [
      "Corporate Law",
      "Criminal Law",
      "International Law",
      "Intellectual Property Law",
      "Tax Law",
      "Constitutional Law",
    ],
  },
  {
    Degree: "Master of Fine Arts or MFA",
    Majors: [
      "Painting",
      "Sculpting",
      "Applied Arts",
      "Art History",
      "Printmaking",
    ],
  },
  {
    Degree: "Master of Public Health or MPH",
    Majors: [
      "Epidemiology",
      "Health Policy and Management",
      "Biostatistics",
      "Environmental Health",
      "Social and Behavioral Sciences",
    ],
  },
  {
    Degree: "Chartered Accountant or CA",
    Majors: ["Inter", "Final"],
  },
  {
    Degree: "PR or Advertising",
    Majors: [],
  },
  {
    Degree: "Company Secretary or CS",
    Majors: [],
  },
  {
    Degree: "Master in Computer Application or MCA",
    Majors: [],
  },
  {
    Degree: "Master of Dental Surgery or MDS",
    Majors: [],
  },
  {
    Degree: "Master of Medicine or MD",
    Majors: [],
  },
  {
    Degree: "Master of Computer Management or MCM",
    Majors: [],
  },
  {
    Degree: "Master of Architecture or M Arch",
    Majors: [],
  },
  {
    Degree: "Master of Commerce or M Com",
    Majors: [],
  },
  {
    Degree: "ICWA or CMA",
    Majors: [],
  },
];

export const postGraduation = pgDegree.map((degree) => ({
  key: degree.Degree,
  label: degree.Degree,
  value: degree.Degree,
  children: degree.Majors.map((majors) => ({
    key: degree.Degree + " in " + majors,
    label: degree.Degree + " in " + majors,
    value: degree.Degree + " in " + majors,
  })),
}));
