import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Checkbox, MenuItem, Select, TextField } from "@mui/material";
import { TagsInput } from "react-tag-input-component";
import TreeSelectInput from "../atoms/TreeSelectInput";
import locations from "../constants/location";
import { industries } from "../constants";
import { underGraduation } from "../constants/undergrad";
import { postGraduation } from "../constants/postgrad";
import { ChangeEvent } from "react";
import { sourceTypes } from "../constants";

const CandidateSearch = ({ formData, setFormData }: any) => {
  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name === "exactKeyword" && formData.mustKeyword === false) {
      setFormData((prevData: any) => ({
        ...prevData,
        mustKeyword: true,
      }));
    }
    if (name === "exactNotKeyword" && formData.mustNotKeyword === false) {
      setFormData((prevData: any) => ({
        ...prevData,
        mustNotKeyword: true,
      }));
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: !prevData[name],
    }));
  };
  const handleKeywordChange = (e: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      keywords: e,
    }));
  };
  const handleOtherLocationsChange = (e: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      otherLocations: e,
    }));
  };
  const handleLanguagesChange = (e: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      languages: e,
    }));
  };
  const handleNotKeywordChange = (e: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      notKeywords: e,
    }));
  };
  const handleMultiSelectChange = (e: any) => {
    let { name, value } = e.target;
    if (value.includes("Any Industry") && value.length > 1) {
      value = value.filter((item: string) => item !== "Any Industry");
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [name]:
        typeof value === "string"
          ? value.split(",").map((element) => element.trim())
          : value,
    }));
  };
  return (
    <Box className="rounded-lg shadow-2xl p-4">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TagsInput
            value={formData.keywords}
            onChange={handleKeywordChange}
            name="keywords"
            placeHolder="Keywords"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TagsInput
            value={formData.notKeywords}
            onChange={handleNotKeywordChange}
            name="notKeywords"
            placeHolder="Not Keywords"
          />
        </Grid>
        <Grid item xs={12} md={3} className="flex items-center">
          <Checkbox
            size="small"
            name="mustKeyword"
            onChange={handleToggle}
            color="primary"
            checked={formData.mustKeyword}
          />
          <Typography className="m-0">Must have all keywords</Typography>
        </Grid>
        <Grid item xs={12} md={3} className="flex items-center">
          <Checkbox
            size="small"
            name="exactKeyword"
            onChange={handleToggle}
            color="primary"
            checked={formData.exactKeyword}
          />
          <Typography className="m-0">Exact keyword match</Typography>
        </Grid>
        <Grid item xs={12} md={3} className="flex items-center">
          <Checkbox
            size="small"
            name="mustNotKeyword"
            onChange={handleToggle}
            color="primary"
            checked={formData.mustNotKeyword}
          />
          <Typography className="m-0">Must not have all not keyword</Typography>
        </Grid>
        <Grid item xs={12} md={3} className="flex items-center">
          <Checkbox
            size="small"
            name="exactNotKeyword"
            onChange={handleToggle}
            color="primary"
            checked={formData.exactNotKeyword}
          />
          <Typography className="m-0">Exact not keyword match</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="small"
            name="expMin"
            label="Min Experience"
            type="number"
            value={formData.expMin}
            onChange={handleFormChange}
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="small"
            name="expMax"
            label="Max Experience"
            type="number"
            value={formData.expMax}
            onChange={handleFormChange}
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TreeSelectInput
            data={locations}
            setForm={setFormData}
            form={formData}
            placeholder="Select Location"
            formKey="location"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TagsInput
            value={formData.otherLocations}
            onChange={handleOtherLocationsChange}
            name="otherLocations"
            placeHolder="Other Locations"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="small"
            name="ctcMin"
            label="Min CTC (LAC)"
            type="number"
            value={formData.ctcMin}
            onChange={handleFormChange}
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="small"
            name="ctcMax"
            label="Max CTC (LAC)"
            type="number"
            value={formData.ctcMax}
            onChange={handleFormChange}
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
        </Grid>
        <Grid item xs={12} md={3} className="flex items-center">
          <FormControl fullWidth size="small">
            <InputLabel>Fresher</InputLabel>
            <Select
              name="fresher"
              value={formData.fresher}
              label="Fresher"
              onChange={handleFormChange}
            >
              <MenuItem value={"any"}>Any</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Notice Period</InputLabel>
            <Select
              native
              name="noticePeriod"
              value={formData.noticePeriod}
              label="Notice Period"
              onChange={handleFormChange}
            >
              <option aria-label="Any" value="99">
                Any
              </option>
              <option aria-label="0-30" value="30">
                0-30
              </option>
              <option aria-label="30-60" value="60">
                30-60
              </option>
              <option aria-label="60-90" value="90">
                60-90
              </option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small">
            <InputLabel>Industry</InputLabel>
            <Select
              name="industry"
              value={formData.industry}
              label="Industry"
              onChange={handleMultiSelectChange}
              renderValue={(selected) => selected.join(", ")}
              input={<OutlinedInput label="Industry" />}
              multiple
            >
              {industries.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={formData.industry.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="currentCompany"
            label="Company"
            value={formData.currentCompany}
            onChange={handleFormChange}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small">
            <InputLabel>Source Type</InputLabel>
            <Select
              name="sourceTypes"
              value={formData.sourceTypes}
              label="Source Type"
              onChange={handleFormChange}
            >
              {sourceTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4.5}>
          <TreeSelectInput
            data={underGraduation}
            setForm={setFormData}
            form={formData}
            placeholder="Select UG"
            formKey="ugCourse"
          />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <TreeSelectInput
            data={postGraduation}
            setForm={setFormData}
            form={formData}
            placeholder="Select PG"
            formKey="pgCourse"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            name="institute"
            label="Institute"
            value={formData.institute}
            onChange={handleFormChange}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ color: "black" }}>Gender</InputLabel>
            <Select
              name="gender"
              value={formData.gender}
              label="Gender"
              onChange={handleFormChange}
              sx={{ bgcolor: "white" }}
            >
              <MenuItem value={"any"}>Any Gender</MenuItem>
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TagsInput
            value={formData.languages}
            onChange={handleLanguagesChange}
            name="languages"
            placeHolder="Languages"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="small"
            name="jobcode"
            value={formData.jobcode}
            onChange={handleFormChange}
            label="Jobcode"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} className="flex items-center">
          <Checkbox
            size="small"
            name="global"
            onChange={handleToggle}
            color="primary"
            className="m-0"
            defaultChecked={formData.global}
          />
          <Typography>Global Search</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CandidateSearch;
